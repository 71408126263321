export class PageConfig {

    dataGrupos(){

        let data = [];
        let qtd = Math.floor(Math.random() * 5);

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    nome: 'Grupo ' + i+1,
                    tipo: 'Administrador',
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        }
        return data;

    }
}
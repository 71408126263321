export class PageConfig {

    data(){

        let data = [];
        let qtd = Math.floor(Math.random() * 20);

        for (let i = 0; i < qtd; i++) {

            let set_status = Math.floor(Math.random() * 2);

            data.push(
                {
                    checkbox: true,
                    status: (set_status) ? true : false,
                    id: i+1,
                    avatar: 'assets/images/avatars/photo.jpg',
                    nome: 'Leandro Melo',
                    usuario: '@leandroO5',
                    telefone: '+23 (951) 574-3150',
                    perfil: 'Grupo ' +i+1,
                    favorito: {icon: (set_status) ? 'star' : 'star_outline', value: 'starred'},
                    isFavorito: (set_status) ? true : false,
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        }
        return data;

    }
    
}
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    onProcessForm: boolean = false;

    constructor(
        private _location: Location
    ) {}

    async sleep(ms) {
        return await new Promise(resolve => {
            setTimeout(resolve, ms)
        })
    }

    searchLocal(search: string, dataSource: Array<any>) {
        return dataSource.filter(item => {
            const has = Object.values(item).filter(element => {
                if (element !== null && element.toString().toLocaleLowerCase().indexOf(search) > -1) {
                    return element;
                }
            });
            return has.length ? true : false;
        })
    }

    backBtn() {
        this._location.back();
    }
}

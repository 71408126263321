import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { first, map } from 'rxjs/operators';
import icSettings from '@iconify/icons-ic/twotone-settings';
import { LayoutService } from '../../services/layout.service';
import icCheck from '@iconify/icons-ic/twotone-check';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Style, StyleService } from '../../services/style.service';
import { ConfigName } from '../../interfaces/config-name.model';
import { ColorVariable, colorVariables } from './color-variables';
import { DOCUMENT } from '@angular/common';
import icClose from '@iconify/icons-ic/twotone-close';
import { ParametersService } from 'app/shared/services/parameters.service';
import { isEqual } from 'lodash';
import { LoaderThemeService } from 'app/shared/services/loader-theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent implements OnInit {

  configs = this.configService.configs;
  colorVariables = colorVariables;

  config$ = this.configService.config$;
  activeConfig$ = this.configService.config$.pipe(
    map(config => Object.keys(this.configService.configs).find(key => this.configService.configs[key] === config))
  );

  isRTL$ = this.route.queryParamMap.pipe(
    map(paramMap => coerceBooleanProperty(paramMap.get('rtl'))),
    first()
  );

  selectedStyle$ = this.styleService.style$;

  icSettings = icSettings;
  icCheck = icCheck;
  icClose = icClose;
  ConfigName = ConfigName;
  Style = Style;
  selectedColor = colorVariables.blue;

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private parametersService: ParametersService,
    private loaderTheme: LoaderThemeService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.selectedColor = this.loaderTheme.color as ColorVariable;

    this.translate.get([
      'COLOR.AMBER',
      'COLOR.BLUE',
      'COLOR.CYAN',
      'COLOR.DEEP_ORANGE',
      'COLOR.DEEP_PURPLE',
      'COLOR.GRAY',
      'COLOR.GREEN',
      'COLOR.ORANGE',
      'COLOR.PINK',
      'COLOR.PURPLE',
      'COLOR.RED',
      'COLOR.TEAL',
    ]).subscribe(res => res);
  }

  setConfig(layout: ConfigName, style: Style) {

    this.parametersService.updateWithKey('THEME_LAYOUT', { value: layout }).subscribe(() => {
      this.configService.setConfig(layout);
    });

    this.parametersService.updateWithKey('THEME_STYLE', { value: 'light' }).subscribe(() => {
      this.styleService.setStyle(style);
    });
  }

  selectColor(color: ColorVariable) {
    this.selectedColor = color;
    this.parametersService.updateWithKey('THEME_COLOR', { value: JSON.stringify(color) }).subscribe(() => {
      this.styleService.setColor(color);
    });
  }

  isSelectedColor(color: ColorVariable) {
    return isEqual(color, this.selectedColor);
  }

  enableDarkMode() {
    /* this.parametersService.updateWithKey('THEME_STYLE', { value: Style.dark }).subscribe(() => {
      this.styleService.setStyle(Style.dark);
    }); */
  }

  disableDarkMode() {
    /* this.parametersService.updateWithKey('THEME_STYLE', { value: Style.default }).subscribe(res => {
      this.styleService.setStyle(Style.default);
    }); */
  }

  sidenavOpenChange(change: MatSlideToggleChange) {
    change.checked ? this.layoutService.openSidenav() : this.layoutService.closeSidenav();
  }

  layoutRTLChange(change: MatSlideToggleChange) {
    change.checked ? this.layoutService.enableRTL() : this.layoutService.disableRTL();
  }


  updateConfig(config: object) {
    this.parametersService.loadOneWithKey('THEME_CONFIG').subscribe(res => {
      const result = res.value ? JSON.parse(res.value) : {};
      const params = { ...result, ...config };
      this.parametersService.updateWithKey('THEME_CONFIG', { value: JSON.stringify(params) }).subscribe(() => {
        this.configService.updateConfig(params);
      });
    });
  }

  toolbarPositionChange(change: MatRadioChange) {
    this.updateConfig({
      toolbar: {
        fixed: change.value === 'fixed'
      }
    });
  }

  footerVisibleChange(change: MatSlideToggleChange) {
    this.updateConfig({
      footer: {
        visible: change.checked
      }
    });
  }

  footerPositionChange(change: MatRadioChange) {
    this.updateConfig({
      footer: {
        fixed: change.value === 'fixed'
      }
    });
  }


  /*
  sidenavCollapsedChange(change: MatCheckboxChange) {
    this.layoutService.setCollapsed(change.checked);
  }

  toolbarVisibleChange(change: MatSlideToggleChange) {
    this.configService.setToolbarVisible(change.checked);
  }

  toolbarPositionChange(change: MatRadioChange) {
    this.configService.setToolbarPosition(change.value);
  }

  footerPositionChange(change: MatRadioChange) {
    this.configService.setFooterPosition(change.value);
  }
  */

}

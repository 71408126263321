import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { StoreModule } from '@ngrx/store';
import { rootReducer } from './store/index.reducers';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpLoaderFactory } from './shared/functions/httpLoaderFactory';
import { SeveralComponentsModule } from './shared/components/several-components/several-components.module';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './shared/services/mat-paginator-intl.service';
import { translateConfig } from './shared/config/translate-config';
import { LoadingModule } from './shared/components/several-components/loading/loading.module';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-BR');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(translateConfig),

    // Material moment date module
    MatMomentDateModule,

    // NgRx
    StoreModule.forRoot(rootReducer, { metaReducers: [storageSyncMetaReducer] }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),

    // Vex
    VexModule,
    CustomLayoutModule,
    SeveralComponentsModule,
    // Loading
    LoadingModule,
  ],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: MatPaginatorI18nService,
  }, {
    provide: LOCALE_ID, useValue: 'pt-BR'
  }],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

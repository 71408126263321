<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center">
    <img [src]="urlIcon$ | async" class="w-8 select-none flex-none" *ngIf="collapsed">
    <img [src]="urlLogo$ | async"
      [ngClass]="{ 'ltr:pl-4': collapsed, 'rtl:pr-8': collapsed, 'ml-12': !collapsed, 'ml-4': collapsed }" class="w-30 title mr-6">
    <!-- <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto"><strong>{{ title$ | async }}</strong></h2> -->
    <button (click)="toggleCollapse()"
            *ngIf="showCollapsePin$ | async"
            [ngClass]="{ 'ml-6': !collapsed, 'ml-4': collapsed }"
            class="w-8 h-8 leading-none flex-none hidden lg:block"
            mat-icon-button
            type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icMenuOpen" size="18px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icMenu" size="18px"></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>

<mat-form-field [formGroup]="formGroup" class="flex-auto" fxFlex="100">
  <mat-label>{{ label }}</mat-label>
  <input matInput [mask]="mask" [attr.disabled]="disabled" [formControlName]="formcontrolname" [required]="checkRequired()"
    [placeholder]="placeholder" (keyup)="onTreatmentMask($event.target.value)" [id]="formcontrolname"
    (keypress)="this.formGroup.get(this.formcontrolname).setValue(onChange($event.target.value))" (blur)="onBlur()"
    #input>
  <mat-icon *ngIf="iconName" matSuffix class="secondary-text">{{ iconName }}</mat-icon>
  <mat-hint align="end" *ngIf="maxLength">{{input.value?.length || 0}}/{{ maxLength }}</mat-hint>
  <mat-error>
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>
</mat-form-field>
import { Injectable } from '@angular/core';
import { ServiceInterface } from '../interfaces/service.interface';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpService } from './http.service';
import { Parameter } from '../models/parameter.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParametersService implements ServiceInterface {
  API_BASE_URL: string = environment.API;

  constructor(private _httpService: HttpService) { }

  loadAll(payload?: any): Observable<Parameter[]> {
    const url = `${this.API_BASE_URL}/parameters`;
    return this._httpService.get(url, payload).pipe(map((result: any) => result));
  }

  loadOne(id: number): Observable<Parameter> {
    const url = `${this.API_BASE_URL}/parameters/${id}`;
    return this._httpService.get(url).pipe(map((result: any) => result));
  }

  loadOneWithKey(key: string): Observable<Parameter> {
    const url = `${this.API_BASE_URL}/unauth-parameters/${key}/key`;
    return this._httpService.get(url).pipe(map((result: any) => result));
  }

  create(payload: any): Observable<Parameter> {
    const url = `${this.API_BASE_URL}/parameters`;
    return this._httpService.postFile(url, payload).pipe(map((result: any) => result));
  }

  update(id: number, payload: any): Observable<Parameter> {
    const url = `${this.API_BASE_URL}/parameters/${id}`;
    return this._httpService.putFile(url, payload).pipe(map((result: any) => result));
  }

  updateWithKey(key: string, payload: any): Observable<Parameter> {
    const url = `${this.API_BASE_URL}/parameters/${key}/key`;
    return this._httpService.putFile(url, payload).pipe(map((result: any) => result));
  }

  toggleStatus(id: number, payload: any): Observable<Parameter> {
    const url = `${this.API_BASE_URL}/parameters/${id}/toggle-status`;
    return this._httpService.put(url, payload).pipe(map((result: any) => result));
  }

  destroy(id: number): boolean {
    return;
  }
}

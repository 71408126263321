import { Component, OnInit } from '@angular/core';
import { ViewInterface } from 'app/shared/interfaces/view.interface';
import { Operation } from 'app/shared/enums/operation';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { Config } from 'app/shared/components/several-components/data-table/config';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from '../form/form.component';

import icView from '@iconify/icons-ic/twotone-visibility';
import icUpdate from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete-forever';
import icSearch from '@iconify/icons-ic/twotone-search';

import icAddCircle from '@iconify/icons-ic/twotone-add-circle';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icGridOn from '@iconify/icons-ic/twotone-grid-on';
import icGridOff from '@iconify/icons-ic/twotone-grid-off';

import icPhone from '@iconify/icons-ic/twotone-phone';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icMail from '@iconify/icons-ic/twotone-mail';
import icChat from '@iconify/icons-ic/twotone-chat';
import icStar from '@iconify/icons-ic/twotone-star';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';

import { TranslateService } from '@ngx-translate/core';

import { PageConfig } from './page-config';
import { GlobalService } from 'app/shared/services/global.service';
import { Router } from '@angular/router';
import { ToastService } from 'app/shared/services/toast.service';
import { SwalService } from 'app/shared/services/swal.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Component({
  selector: 'vex-app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
  constructor(
    private _translate: TranslateService,
    private _router: Router,
    public globalService: GlobalService,
    private _toastService: ToastService,
    private _swalService: SwalService
  ) { }

  title = 'Usuários';
  icSearch = icSearch;

  icView = icView;
  icUpdate = icUpdate;
  icDelete = icDelete;

  icAddCircle = icAddCircle;
  icFilterList = icFilterList;
  icRefresh = icRefresh;
  icGridOn = icGridOn;
  icGridOff = icGridOff;

  icBusiness = icBusiness;
  icPhone = icPhone;
  icMail = icMail;
  icChat = icChat;
  icStar = icStar;
  icStarBorder = icStarBorder;

  typeView = 'grid';
  searchCtrl = new FormControl();

  operation: Operation;

  //TABLE
  paginationInitial = { page: 1, limit: 10 };
  options = {};

  selection = new SelectionModel<any>(true, []);
  configuration = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  dataSource: any = [];
  dataSourceBase: any = [];

  columns: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: '', property: 'avatar', type: 'image', visible: true },

    { label: 'Nome', property: 'nome', type: 'text', visible: true },
    { label: 'Usuário', property: 'usuario', type: 'text', visible: true },

    { label: '', property: 'perfil', type: 'badge', visible: true, cssClasses: ['text-green', 'bg-green-light'] },

    { label: '', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  ngOnInit(): void {
    this.onRefresh();

    if (this.searchCtrl) {
      this.searchCtrl.valueChanges.pipe(debounceTime(500)).subscribe(value => this.onSearch(value));
    }
  }

  onRefresh(event?): void {
    this.dataSource = [];
    this.dataSource = new PageConfig().data();
    this.dataSourceBase = this.dataSource;
    this.configuration.total = this.dataSource.length;
  }

  onCreate(): void {
    this._router.navigate([`auth/users/new`]);
  }

  onClickRow(row): void {
    this._router.navigate([`auth/users/${row.id}`]);
  }

  onSearch(search: string) {
    this.dataSource = this.globalService.searchLocal(search, this.dataSourceBase)
  }

  onStarred(row){
    if(row.favorito.icon === 'star'){
      row.favorito.icon = 'star_outline';
      row.isFavorito = false;
    
    }else{
      row.favorito.icon = 'star';
      row.isFavorito = true;
    }
  }

  onDelete(row) {
    this._swalService.confirm(`Excluir registro #${row.id}`, 'Tem certeza que deseja excluir este registro?')
    .then((res) => {
      if(res.value){
        this._toastService.success('O registro foi excluído!');
      }
    })
  }

  getAction(event){
    switch (event.action) {
      case 'delete': 
        this.onDelete(event.row);
        break;

      case 'starred': 
        this.onStarred(event.row);
        break;

      default:
        true;
    }
  }

  onChangeView(type){
    (type === 'list') ? this.typeView = 'grid' : this.typeView = 'list';
  }

  onToggleStatus(row): void { }
}

export class PageConfig {

    data(){

        let data = [];
        let qtd = 10;

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    titulo: 'Experiencia ' + (i+1),
                    descricao: 'Lorem ipsum dollor Lorem ipsum dollor ',
                    ano: '2021',
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        }
        return data;

    }
    
}
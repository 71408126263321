<h3 matDialogTitle>
  {{ event.title }}
</h3>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>Descrição da Agenda</mat-label>
        <input formControlName="title" matInput>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Início</mat-label>
        <input [matDatepicker]="startDatepicker" formControlName="start" matInput>
        <mat-datepicker-toggle [for]="startDatepicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Fim</mat-label>
        <input [matDatepicker]="endDatepicker" formControlName="end" matInput>
        <mat-datepicker-toggle [for]="endDatepicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="flex justify-end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button (click)="save()" class="save" color="primary" mat-raised-button>Salvar</button>
</mat-dialog-actions>

import { Injectable } from '@angular/core';
import { StyleService, Style } from '@vex/services/style.service';
import { ConfigService } from '@vex/services/config.service';
import { ColorVariable } from '@vex/components/config-panel/color-variables';
import { ConfigName } from '@vex/interfaces/config-name.model';
import { AclService } from './acl.service';
import { NavigationService } from '@vex/services/navigation.service';
import { NavigationItem } from '@vex/interfaces/navigation-item.interface';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Auth } from '../models/auth.model';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LoaderMenuService {

  authData: Observable<Auth>;
  token: string;

  constructor(
    private aclService: AclService,
    private navigationService: NavigationService,
    private _store: Store<{ auth: Auth }>,
    private translate: TranslateService,
  ) {

    // this.authData = this._store.pipe(select('auth'));

    // this.authData.subscribe(data => {
    //   this.token = data.token;
    //   this.boot();
    // });
  }

  defaultAcl: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'ACL',
      children: [
        {
          type: 'link',
          route: '/auth/acl/actions',
          label: 'Acoes',
        },
        {
          type: 'link',
          route: '/auth/acl/screens',
          label: 'Telas',
        },
        {
          type: 'link',
          route: '/auth/acl/roles',
          label: 'Regras',
        },
        {
          type: 'link',
          label: 'Grupos de usuarios',
          route: '/auth/users-group',
        },
        {
          type: 'link',
          label: 'Usuarios',
          route: '/auth/users',
        },
      ],
    }];

  boot() {
    // if (this.token) {
    //   return this.aclService.getUserMenus();
    // }
  }




  default() {
    const titles = ['AUTH.ABSENCE_REASONS.TITLE', 'AUTH.ABSENCES.TITLE', 'AUTH.ACCOUNT_ERP.TITLE', 'AUTH.ACCOUNTS.TITLE', 'AUTH.ARTICLE_CATEGORIES.TITLE', 'AUTH.ARTICLES.TITLE', 'AUTH.EVENT_TYPES.TITLE', 'AUTH.EVENTS.TITLE', 'AUTH.FAQ_GROUPS.TITLE', 'AUTH.FAQ.TITLE', 'AUTH.FAQS.TITLE', 'AUTH.FILE_MANAGER.TITLE', 'AUTH.FORUM_CATEGORIES.TITLE', 'AUTH.FORUM_TOPICS.TITLE', 'AUTH.MAINTENANCE_TYPES.TITLE', 'AUTH.MAINTENANCE.TITLE', 'AUTH.MEASURED_UNITS.TITLE', 'AUTH.MENU_GROUPS.TITLE', 'AUTH.MENU_ITENS.TITLE', 'AUTH.NOTICE_TYPES.TITLE', 'AUTH.NOTICES.TITLE', 'AUTH.OCCUPATIONS.TITLE', 'AUTH.PARAMETERS.TITLE', 'AUTH.PRODUCT_GROUPS.TITLE', 'AUTH.PRODUCT_TYPES.TITLE', 'AUTH.TERMS.TITLE', 'AUTH.TICKETS.TITLE', 'AUTH.TICKET_AREAS.TITLE', 'AUTH.TICKET_CATEGORIES.TITLE', 'AUTH.TICKET_TEAMS.TITLE', 'AUTH.TODO_LISTS.TITLE', 'AUTH.USER_GROUPS.TITLE', 'AUTH.USERS.TITLE', 'AUTH.VOTE_CATEGORIES.TITLE', 'AUTH.VOTES.TITLE', 'AUTH.WAREHOUSE_LOCATIONS.TITLE', 'AUTH.WAREHOUSES.TITLE', 'AUTH.SOCIAL_PROFILE.TITLE', 'AUTH.CONTACTS.TITLE'];
    this.translate.get(titles).subscribe(result => {
      this.navigationService.items = [
        {
          type: 'link',
          label: 'Sair',
          route: '/auth/logout'
        }
      ];
      this.navigationService.items = [
        {
          type: 'subheading',
          label: 'ACL',
          children: [
            {
              type: 'link',
              route: '/auth/acl/actions',
              label: 'Acoes',
            },
            {
              type: 'link',
              route: '/auth/acl/screens',
              label: 'Telas',
            },
            {
              type: 'link',
              route: '/auth/acl/roles',
              label: 'Regras',
            },
            {
              type: 'link',
              label: result['AUTH.USER_GROUPS.TITLE'],
              route: '/auth/users-group',
            },
            {
              type: 'link',
              label: result['AUTH.USERS.TITLE'],
              route: '/auth/users',
            },
          ],
        },
        {
          type: 'link',
          label: 'Dashboard',
          route: '/auth/dashboard',
        },
        {
          type: 'link',
          label: result['AUTH.MENU_GROUPS.TITLE'],
          route: '/auth/menu-groups',
        },
        {
          type: 'link',
          label: result['AUTH.MENU_ITENS.TITLE'],
          route: '/auth/menu-itens',
        },

        {
          type: 'link',
          label: result['AUTH.FAQ_GROUPS.TITLE'],
          route: '/auth/faq-groups',
        },
        {
          type: 'link',
          label: result['AUTH.FAQ.TITLE'],
          route: '/auth/faq',
        },
        {
          type: 'link',
          label: result['AUTH.FAQS.TITLE'],
          route: '/auth/faqs',
        },
        {
          type: 'link',
          label: result['AUTH.FORUM_CATEGORIES.TITLE'],
          route: '/auth/forum-categories',
        }, {
          type: 'link',
          label: result['AUTH.FORUM_TOPICS.TITLE'],
          route: '/auth/forum-topics',
        }, {
          type: 'link',
          label: result['AUTH.NOTICE_TYPES.TITLE'],
          route: '/auth/notice-types',
        },
        {
          type: 'link',
          label: result['AUTH.NOTICES.TITLE'],
          route: '/auth/notices',
        },
        {
          type: 'link',
          label: result['AUTH.VOTE_CATEGORIES.TITLE'],
          route: '/auth/vote-categories',
        }, {
          type: 'link',
          label: result['AUTH.VOTES.TITLE'],
          route: '/auth/votes',
        },
        {
          type: 'link',
          label: result['AUTH.TICKET_AREAS.TITLE'],
          route: '/auth/ticket-areas',
        },
        {
          type: 'link',
          label: result['AUTH.TICKET_CATEGORIES.TITLE'],
          route: '/auth/ticket-categories',
        },
        {
          type: 'link',
          label: result['AUTH.TICKET_TEAMS.TITLE'],
          route: '/auth/ticket-teams',
        },
        {
          type: 'link',
          label: result['AUTH.TODO_LISTS.TITLE'],
          route: '/auth/todo-lists',
        },
        {
          type: 'link',
          label: result['AUTH.MAINTENANCE_TYPES.TITLE'],
          route: '/auth/maintenance-types',
        },
        {
          type: 'link',
          label: result['AUTH.MAINTENANCE.TITLE'],
          route: '/auth/maintenance',
        },
        {
          type: 'link',
          label: result['AUTH.ACCOUNT_ERP.TITLE'],
          route: '/auth/account-erp',
        },
        {
          type: 'link',
          label: result['AUTH.ACCOUNTS.TITLE'],
          route: '/auth/accounts',
        },
        {
          type: 'link',
          label: result['AUTH.TERMS.TITLE'],
          route: '/auth/terms',
        },
        {
          type: 'link',
          label: result['AUTH.PARAMETERS.TITLE'],
          route: '/auth/parameters',
        },
        {
          type: 'link',
          label: result['AUTH.CONTACTS.TITLE'],
          route: '/auth/contacts',
        },
        {
          type: 'link',
          label: result['AUTH.MEASURED_UNITS.TITLE'],
          route: '/auth/measured-units',
        },
        {
          type: 'link',
          label: result['AUTH.OCCUPATIONS.TITLE'],
          route: '/auth/occupations',
        }, {
          type: 'link',
          label: result['AUTH.PRODUCT_GROUPS.TITLE'],
          route: '/auth/product-groups',
        }, {
          type: 'link',
          label: result['AUTH.PRODUCT_TYPES.TITLE'],
          route: '/auth/product-types',
        }, {
          type: 'link',
          label: result['AUTH.WAREHOUSES.TITLE'],
          route: '/auth/warehouses',
        }, {
          type: 'link',
          label: result['AUTH.WAREHOUSE_LOCATIONS.TITLE'],
          route: '/auth/warehouses-location',
        }, {
          type: 'link',
          label: result['AUTH.ABSENCE_REASONS.TITLE'],
          route: '/auth/absence-reasons',
        }, {
          type: 'link',
          label: result['AUTH.ABSENCES.TITLE'],
          route: '/auth/absences',
        }, {
          type: 'link',
          label: result['AUTH.EVENT_TYPES.TITLE'],
          route: '/auth/event-types',
        }, {
          type: 'link',
          label: result['AUTH.EVENTS.TITLE'],
          route: '/auth/events',
        }, {
          type: 'link',
          label: result['AUTH.FILE_MANAGER.TITLE'],
          route: '/auth/file-manager',
        }, {
          type: 'link',
          label: result['AUTH.ARTICLE_CATEGORIES.TITLE'],
          route: '/auth/article-categories',
        }, {
          type: 'link',
          label: result['AUTH.ARTICLES.TITLE'],
          route: '/auth/articles',
        }, {
          type: 'link',
          label: result['AUTH.SOCIAL_PROFILE.TITLE'],
          route: '/auth/social-profile',
        }
      ];

    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { AclService } from 'app/shared/services/acl.service';
import { NavigationService } from '@vex/services/navigation.service';

import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icAssessment from '@iconify/icons-ic/twotone-assessment';
import icPeople from '@iconify/icons-ic/twotone-people';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icBusinessCenter from '@iconify/icons-ic/twotone-business-center';
import icBugReport from '@iconify/icons-ic/twotone-bug-report';
import icReport from '@iconify/icons-ic/twotone-report';
import icReportOff from '@iconify/icons-ic/twotone-report-off';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icInfo from '@iconify/icons-ic/twotone-info';
import icSupervisorAccount from '@iconify/icons-ic/twotone-supervisor-account';
import icPersonOutline from '@iconify/icons-ic/twotone-person-outline';
import icChromeReaderMode from '@iconify/icons-ic/twotone-chrome-reader-mode';
import icPhoneAndroid from '@iconify/icons-ic/twotone-phone-android';
import icPhoneIphone from '@iconify/icons-ic/twotone-phone-iphone';
import icStar from '@iconify/icons-ic/twotone-star';
import icMoney from '@iconify/icons-ic/twotone-attach-money';
import icPerson from '@iconify/icons-ic/twotone-person';

import { ProfilesEnum } from 'app/shared/enums/profile';

@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  items = [];

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private aclService: AclService,
    private router: Router
  ) { }

  ngOnInit() {

    this.items = [
      
      {
        type: 'subheading',
        label: 'Dash',
        children: [
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Meu Perfil',
            route: '/auth/profile',
            icon: icPerson
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Agenda',
            route: '/auth/schedule',
            icon: icDateRange
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Indicadores',
            route: '/auth/dashboard',
            icon: icAssessment
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Cadastros',
        children: [
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Áreas',
            route: '/auth/areas',
            icon: icPeople
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Especialidades',
            route: '/auth/specialties',
            icon: icAssigment
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Procedimentos',
            route: '/auth/procedures',
            icon: icReceipt,
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Qualificações',
            route: '/auth/qualifications',
            icon: icStar
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Formas Pgto',
            route: '/auth/payment-methods',
            icon: icMoney
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Convênios',
            route: '/auth/covenants',
            icon: icBusinessCenter
          }
        ]
      },

      /* {
        type: 'subheading',
        label: 'Modelos',
        children: [
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Clientes',
            route: '/auth/customers',
            icon: icPeople
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Contratos',
            route: '/auth/contracts',
            icon: icAssigment
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Ordens serviço',
            route: '/auth/service-orders',
            icon: icReceipt,
            badge: {
              value: 12,
              bgClass: 'bg-red',
              textClass: 'text-red-contrast',
            },
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Pragas',
            route: '/auth/pests',
            icon: icBugReport
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Produtos',
            route: '/auth/products',
            icon: icBusinessCenter
          }
        ]
      }, */

      {
        type: 'subheading',
        label: 'Configurações',
        children: [
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Perfis',
            route: '/auth/profiles',
            icon: icSupervisorAccount
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Usuários',
            route: '/auth/users',
            icon: icPersonOutline
          },
          {
            profile: [ProfilesEnum.ADMIN, ProfilesEnum.CLINICA, ProfilesEnum.PROFISSIONAL, ProfilesEnum.PACIENTE],
            type: 'link',
            label: 'Auditoria',
            route: '/auth/audit',
            icon: icChromeReaderMode
          }
        ]
      },

    ];

    /* this.aclService.getUserMenus().subscribe(res => {
      this.items = res;
      console.log('items', this.items);
    });

    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close()); */
  }
}

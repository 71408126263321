<form (ngSubmit)="save()" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 *ngIf="form.get('descricao').value"
        class="headline m-0"
        fxFlex="auto">{{ form.get('descricao').value }}</h2>
    <h2 *ngIf="!form.get('descricao').value"
        class="headline m-0"
        fxFlex="auto">Nova qualificação</h2>

    <button [matMenuTriggerFor]="actionsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon [icIcon]="icMoreVert"></mat-icon>
    </button>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">

    <div class="mt-4" fxLayout="row" fxLayoutGap="8px">

        <mat-form-field fxFlex="20">
            <mat-label>Id</mat-label>
            <input formControlName="id" matInput required>
        </mat-form-field>

        <mat-form-field fxFlex="auto">
          <mat-label>Descrição</mat-label>
          <input formControlName="descricao" matInput required>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutGap="8px">

        <div fxFlex="auto" fxLayout="column">
            <mat-label>Status</mat-label>
            <mat-slide-toggle formControlName="status" color="primary"></mat-slide-toggle>
        </div>

    </div>

    <div class="flex flex-col sm:flex-row">
      <div class="flex-auto">&nbsp;</div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancelar</button>
    <button *ngIf="isCreateMode()" color="primary" mat-button type="submit">Salvar</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">Atualizar</button>
  </mat-dialog-actions>
</form>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icPrint"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDelete"></mat-icon>
    <span>Excluir</span>
  </button>
</mat-menu>

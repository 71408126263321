import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInUp400ms } from 'src/../@vex/animations/fade-in-up.animation';
import icMail from '@iconify/icons-ic/twotone-mail';
import { TranslateService } from '@ngx-translate/core';
import { colorVariables } from '@vex/components/config-panel/color-variables';
import { GlobalService } from 'app/shared/services/global.service';
import { ToastService } from 'app/shared/services/toast.service';

@Component({
  selector: 'vex-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInUp400ms]
})
export class ForgotPasswordComponent implements OnInit {

  form = this.fb.group({
    email: [null, Validators.required]
  });

  icMail = icMail;

  colorVariables = colorVariables;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public globalService: GlobalService,
    private _toastService: ToastService,
  ) { }

  ngOnInit() {
  }

  async send() {
    // show loader bar
    this.globalService.onProcessForm = !this.globalService.onProcessForm;

    this._toastService.success(`Enviamos para: ${this.form.controls.email.value} uma mensagem com os dados de recuperação`);
    await this.globalService.sleep(2000);
    this.globalService.onProcessForm = !this.globalService.onProcessForm;
    this.router.navigate(['/']);

  }
}

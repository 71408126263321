<vex-page-layout>

  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div class="px-gutter bg-primary w-full h-full flex flex-col flex flex-col sm:flex-row justify-between">
      <div class="pt-6 text-primary-contrast">
        <h1 class="title mt-0 mb-1 text-primary-contrast">{{title}}</h1>
        <vex-breadcrumbs [crumbs]="[title]"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>

  <vex-page-layout-content class="-mt-16 px-gutter">

    <div class="flex flex-col -mt-16">
      <div class="bg-app-bar relative flex-none card">
        <div class="container py-4 px-6 z-10 relative flex items-end" style="max-width: 100% !important;">
          <div class="flex-auto flex flex-col sm:flex-row justify-between items-center">
            <div class="headline">{{ viewDate | calendarDate:(view + 'ViewTitle'):'pt-BR' }}</div>
    
            <div class="actions flex flex-col sm:flex-row justify-end items-center">
              <div class="chevrons">
                <button [(viewDate)]="viewDate" [view]="view" mat-icon-button mwlCalendarPreviousView>
                  <mat-icon [icIcon]="icChevronLeft"></mat-icon>
                </button>
                <button [(viewDate)]="viewDate" [view]="view" mat-icon-button mwlCalendarNextView>
                  <mat-icon [icIcon]="icChevronRight"></mat-icon>
                </button>
              </div>
              <div class="flex">
                <button (click)="view = CalendarView.Month" mat-button>MÊS</button>
                <button (click)="view = CalendarView.Week" mat-button>SEMANA</button>
                <button (click)="view = CalendarView.Day" mat-button>DIA</button>
                <button mat-icon-button (click)="addEvent()" color="primary" [matTooltip]="'Adicionar'">
                  <mat-icon [icIcon]="icAddCircle"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div [ngSwitch]="view" class="px-0 shadow">
        <mwl-calendar-month-view
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          *ngSwitchCase="'month'"
          [activeDayIsOpen]="activeDayIsOpen"
          [events]="events"
          [refresh]="refresh"
          [viewDate]="viewDate">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          *ngSwitchCase="'week'"
          [events]="events"
          [refresh]="refresh"
          [viewDate]="viewDate">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          *ngSwitchCase="'day'"
          [events]="events"
          [refresh]="refresh"
          [viewDate]="viewDate">
        </mwl-calendar-day-view>
      </div>
    </div>

  </vex-page-layout-content>

</vex-page-layout>

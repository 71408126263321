export class PageConfig {

    data(rand: boolean = false){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                descricao: 'Odontologia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 2,
                descricao: 'Psicologia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 3,
                descricao: 'Fisioterapia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 4,
                descricao: 'Oftalmologia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 5,
                descricao: 'Nutrição',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 6,
                descricao: 'Fonoaudiologia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 7,
                descricao: 'Medicina',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: false,
                id: 8,
                descricao: 'Beauty Care',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
        ];

        return data;

    }
    
}
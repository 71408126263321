<mat-form-field [formGroup]="formGroup" fxFlex appearance="outline" fxFlex="100">
  <mat-label>{{label}}</mat-label>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <input type="datetime-local" [readonly]="readonly" [required]="checkRequired(formcontrolname)" matInput 
      [placeholder]="placeholder" [formControlName]="formcontrolname" autocomplete="off" (change)="verifyDate($event)">
    <mat-icon matTooltip="Limpar" matTooltipPosition="above" *ngIf="(formGroup.get(formcontrolname).value && cleanInput)"
      (click)="resetField()" class="icon">close</mat-icon>
  </div>
  <mat-error>
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>
</mat-form-field>
<vex-page-layout>

  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div class="px-gutter bg-primary w-full h-full flex flex-col flex flex-col sm:flex-row justify-between">
      <div class="pt-6 text-primary-contrast">
        <h1 class="title mt-0 mb-1 text-primary-contrast">{{title}}</h1>
        <vex-breadcrumbs [crumbs]="[title]"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>

  <vex-page-layout-content class="-mt-16 px-gutter">

    <div class="-mt-16 card overflow-hidden">
      <div class="h-64 relative overflow-hidden">
        <img class="w-full h-full object-cover" src="assets/images/capas/landscape.jpg">
        <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-2"></div>
  
        <img class="avatar h-24 w-24 absolute top-6 ltr:left-4 rtl:right-4 sm:hidden"
             [src]="user.urlPhoto">
      </div>
  
      <div class="z-10 relative -mt-16 px-gutter flex items-center">
  
        <img class="avatar h-24 w-24 flex-none align-start hidden sm:block border-3 border-white"
             [src]="user.urlPhoto">
  
        <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
          <div class="h-16 flex items-center">
            <h1 @fadeInRight class="headline text-contrast-white m-0">{{ user.username }}</h1>
          </div>
  
          <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
            <a #rla="routerLinkActive"
               *ngFor="let link of links"
               [active]="rla.isActive"
               [disabled]="link.disabled"
               [routerLink]="link.route"
               mat-tab-link
               queryParamsHandling="preserve"
               [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
               routerLinkActive>
              {{ link.label }}
            </a>
          </nav>
        </div>
      </div>
    </div>
  
    <router-outlet></router-outlet>

  </vex-page-layout-content>

</vex-page-layout>
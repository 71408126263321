import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/visibility';
import icVisibilityOff from '@iconify/icons-ic/visibility-off';
@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formcontrolname: string;

  @Input() label = 'Senha';
  @Input() placeholder = '';

  showPass = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor() { }

  ngOnInit(): void { }

  showPassword(): void {
    this.showPass = !this.showPass;
  }

}

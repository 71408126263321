import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { ViewComponent } from './view/view.component';
import { PageLayoutModule } from '@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from '@vex/components/breadcrumbs/breadcrumbs.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContainerModule } from '@vex/directives/container/container.module';
import { FormComponent } from './form/form.component';
import { IconModule } from '@visurel/iconify-angular';

const routes: Routes = [

  {
    path: 'index',
    pathMatch: 'full',
    redirectTo: '',
  },

  {
    path: '',
    component: ViewComponent
  },
  {
    path: ':id',
    component: FormComponent
  },
];

@NgModule({
  declarations: [
    ViewComponent,
    FormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    PageLayoutModule,
    BreadcrumbsModule,
    FlexLayoutModule,
    ContainerModule,
    IconModule
  ],
})
export class QualificationsModule { }

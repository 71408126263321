interface Options {
    generalButtons?: {
        icon: any,
        title: string,
        function: (data: []) => void
    }[];
    selectionButtons?: {
        icon: any,
        title: string,
        function: (data: []) => void
    }[];
    toggleButtons?: {
        [key: string]: (data: [] | any) => void
    };
    buttons?: {
        [key: string]: {
            icon: any,
            function: (data: [] | any) => void
        }
    };
    labels?: {
        [key: string]: {
            id: number;
            text: string;
            color: string;
        }[]
    };
    onLabelChange?: {
        [key: string]: (event, row: any) => void
    };
}
export class Config {
    private _options: Options;
    private _total: number;

    constructor(options: Options, total: number) {
        this._options = options;
        this._total = total;
    }

    get options(): Options {
        return this._options;
    }

    set options(value: Options) {
        this._options = value;
    }

    get total(): number {
        return this._total;
    }

    set total(value: number) {
        this._total = value;
    }
}

<vex-page-layout-content [class.px-gutter]="true" class="-mt-6">

  <div class="card overflow-auto -mt-16">
    <div *ngIf="headerShow" class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
      <h6 class="my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
        <!-- <span *ngIf="selection.isEmpty()">{{ title }}</span> -->
        <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
          <span *ngIf="selection.selected.length <= 1">{{ 'COMMON.REGISTER' | translate | lowercase }}</span>
          <span *ngIf="selection.selected.length > 1">{{ 'COMMON.REGISTERS' | translate | lowercase }}</span>
          {{ 'COMMON.SELECTED' | translate | lowercase }}
        </span>
      </h6>

      <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r" fxFlex="none">
        <button *ngFor="let button of config.options?.selectionButtons" color="primary" mat-icon-button
          [matTooltip]=" button.title | translate " type="button" (click)="button.function(selection.selected);">
          <mat-icon>{{ button.icon }}</mat-icon>
        </button>
      </div>

      <div class="bg-card rounded-full border px-4" fxFlex="70" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
        fxLayoutAlign="start center">
        <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
        <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
          [placeholder]="('COMMON.SEARCH' | translate) + '...'" type="search">
      </div>

      <span fxFlex></span>

      <button class="ml-4" fxFlex="none" fxHide.gt-xs mat-icon-button type="button">
        <mat-icon [icIcon]="icSearch"></mat-icon>
      </button>

      <button @fadeInUp *ngIf="typeViewShow" class="ml-4" fxFlex="none" mat-icon-button
      (click)="onChangeView(typeView)" color="primary"
        [matTooltip]="(typeView === 'grid') ? 'Exibir em Lista' : 'Exibir em Grade'" type="button">
        <mat-icon [icIcon]="(typeView === 'grid') ? icGridOn : icGridOff"></mat-icon>
      </button>

      <button @fadeInUp [matMenuTriggerFor]="columnFilterMenu" fxFlex="none" mat-icon-button
        [matTooltip]="'BUTTON.FILTER_COLUMNS' | translate" type="button">
        <mat-icon [icIcon]="icFilterList"></mat-icon>
      </button>

      <!-- <button @fadeInUp *ngFor="let button of config.options?.generalButtons" (click)="button.function($event)"
        class="ml-4" color="primary" fxFlex="none" mat-mini-fab [matTooltip]="button.title | translate" type="button">
        <mat-icon>{{ button.icon }}</mat-icon>
      </button>

      <button @fadeInUp (click)="onCreate()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
        [matTooltip]="'BUTTON.ADD' | translate" type="button">
        <mat-icon [icIcon]="icAdd"></mat-icon>
      </button>


      <button @fadeInUp (click)="onRefresh()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
        [matTooltip]="'BUTTON.REFRESH' | translate" type="button">
        <mat-icon [icIcon]="icRefresh"></mat-icon>
      </button> -->

      <button @fadeInUp *ngIf="starredShow" mat-icon-button (click)="onStarred()" color="primary" [matTooltip]="'Exibir apenas Favoritos'">
        <mat-icon [icIcon]="starredIcon"></mat-icon>
      </button>

      <button @fadeInUp *ngIf="createShow" mat-icon-button (click)="onCreate()" color="primary" [matTooltip]="'BUTTON.ADD' | translate">
        <mat-icon [icIcon]="icAddCircle"></mat-icon>
      </button>

      <button @fadeInUp mat-icon-button (click)="onRefresh()" color="primary" [matTooltip]="'BUTTON.REFRESH' | translate">
        <mat-icon [icIcon]="icRefresh"></mat-icon>
      </button>

    </div>

    <div *ngIf="dataSource.filteredData.length > 0">

      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort (matSortChange)="sortChange($event)">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->


        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <!-- Checkbox Column -->
          <ng-container *ngIf="column.type === 'checkbox'" [matColumnDef]="column.property" style="display: none;">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="$event ? masterToggle($event) : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary"
                (click)="$event.stopPropagation()">
              </mat-checkbox>
            </th>
            <td *matCellDef="let row" class="w-4" mat-cell>
              <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
                [checked]="selection.isSelected(row)" color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Image Column -->
          <ng-container *ngIf="column.type === 'image'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
              <img [src]="row[column.property] || DEFAULT_AVATAR" class="avatar h-8 w-8 align-middle">
            </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container *ngIf="column.type === 'color'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label | translate }}</th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
              <mat-icon [icIcon]="icColor" [color]="row[column.property]"></mat-icon>
            </td>
          </ng-container>

          <!-- Toggle Column -->
          <ng-container *ngIf="column.type === 'toggle'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell (click)="$event.stopPropagation()">
              <mat-slide-toggle [checked]="row[column.property]" color="primary"
                [disabled]="isButtonToggle(column.property)"
                (change)="toggleButtonFunction($event, column.property, row)"></mat-slide-toggle>
            </td>
          </ng-container>

          <!-- ID Columns -->
          <ng-container *ngIf="column.type === 'id'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>{{ row[column.property] | translate }}</td>
          </ng-container>

          <!-- Badges Columns -->
          <ng-container *ngIf="column.type === 'badge'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
              <div class="rounded px-2 py-1 font-medium text-xs text-center"
                  [ngClass]="column.cssClasses">
                {{ row[column.property] | translate }}
              </div>
            </td>
          </ng-container>

          <!-- Text Columns -->
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ (row[column.property] | translate) || '-' }}</td>
          </ng-container>

          <!-- Icon Columns -->
          <ng-container *ngIf="column.type === 'icon'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <mat-icon>{{ row[column.property] || '-'  }}</mat-icon>
            </td>
          </ng-container>

          <!-- Label Column -->
          <ng-container *ngIf="column.type === 'labels'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
            <td *matCellDef="let row" mat-cell>
              <div (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="4px">
                <div *ngFor="let label of row[column.property]?.slice(0, 4);"
                  [ngStyle]="{ backgroundColor: label.color }" class="rounded px-2 py-1 font-medium text-xs text-white"
                  fxFlex="none">
                  {{ label.text }}
                </div>
                <div (click)="labelSelect.open()" class="text-secondary bg-base text-hint cursor-pointer hover:bg-hover"
                  fxFlex="none" fxLayout="row" fxLayoutAlign="center center">
                  <ic-icon [icon]="icAdd" size="18px"></ic-icon>
                </div>

                <mat-form-field class="invisible">
                  <mat-select #labelSelect="matSelect"
                    (selectionChange)="onLabelChangeFunction($event, column.property, row)"
                    [value]="getCurrentLabels(column.property, row)" style="width: 100%;"
                    class="invisible w-0 h-0 text-sm" multiple>

                    <mat-option>
                      <ngx-mat-select-search [placeholderLabel]="'COMMON.SEARCH' | translate"
                        [noEntriesFoundLabel]="'COMMON.NO_RECORD_FOUND' | translate"
                        [formControl]="getDataFilterCtrl(column.property)"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let label of filteredData[column.property] | async" [value]="label">
                      <div [ngStyle]="{ backgroundColor: label.color }"
                        class="h-6 w-6 align-middle  ltr:mr-2 rtl:ml-2 rounded inline-block"></div>
                      <span class="secondary-text">{{ label.text }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
          </ng-container>

          <!-- Label Static Column -->
          <ng-container *ngIf="column.type === 'labels-static'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
            <td *matCellDef="let row" mat-cell>
              <div (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="4px">
                <div *ngFor="let label of row[column.property]?.slice(0, 4);"
                  [ngClass]="label.colors" class="rounded px-2 py-1 font-medium text-xs"
                  fxFlex="none">
                  {{ label.text }}
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Label Static Column -->
          <ng-container *ngIf="column.type === 'input-counter'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
            <td *matCellDef="let row" mat-cell>
              <div (click)="$event.stopPropagation()" fxLayoutAlign="end center" fxLayoutGap="4px">

                <button mat-icon-button type="button" color="primary" (click)="onDelCounter(row)">
                  <mat-icon [icIcon]="icRemoveCircle"></mat-icon>
                </button>

                <span>{{ row.counter }}</span>

                <button mat-icon-button type="button" color="primary" (click)="onAddCounter(row)">
                  <mat-icon [icIcon]="icAddCircle"></mat-icon>
                </button>

              </div>
            </td>
          </ng-container>

          <!-- Button Column -->
          <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">

            <ng-container *ngIf="column.property === 'starred'">
              <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-10 text-center" mat-cell
                (click)="$event.stopPropagation()">
                <button (click)="buttonFunction($event, column.property, row)" mat-icon-button type="button">
                  <mat-icon [icIcon]="getButtonIcon(column.property)"
                    [ngClass]="row[column.property] ? 'text-amber' : 'text-black'">
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container *ngIf="column.property !== 'starred'">
              <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-8 min-w-8 pr-0 text-center" mat-cell
                (click)="$event.stopPropagation()">
                <button (click)="sendFunction(row[column.property].value, row)" mat-icon-button type="button"
                matTooltip="{{column.tooltip}}">
                  <mat-icon [ngClass]="column.cssClasses">{{ row[column.property].icon || '-'  }}</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container *ngIf="column.property === 'actions'">
              <th *matHeaderCellDef mat-header-cell [lang]="'LABEL.ACTIONS' | translate"></th>
              <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                <button (click)="$event.stopPropagation()" *ngIf="row.actions && row.actions.length > 0"
                  [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                  <mat-icon [icIcon]="icMoreVert"></mat-icon>
                </button>

                <mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
                  <button mat-menu-item *ngFor="let button of row.actions || []" (click)="button.function(row)">
                    <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
                    <span>{{ button.title | translate }}</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr (click)="onClickRow(row)" @fadeInUp *matRowDef="let row; columns: visibleColumns;"
          class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>

      </table>
      <mat-paginator *ngIf="disablePagination === false" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [pageIndex]="pageIndex" [length]="length"
        class="sticky left-0" >
      </mat-paginator>

    </div>
    <div *ngIf="dataSource.filteredData.length == 0" class="w-full empty-list">
      <mat-icon [icIcon]="icEmptyList" size="24px" class="mr-5"></mat-icon>
      {{ 'COMMON.NO_RECORD_FOUND' | translate | uppercase }}
    </div>
  </div>

</vex-page-layout-content>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
    class="checkbox-item mat-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ (!column.label || column.label === '') ? column.labelFilter : column.label | translate | titlecase }}
    </mat-checkbox>
  </button>
</mat-menu>
export class PageConfig {

    data(){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                nome: 'Admin',
                tipo: 'Administrador',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 1,
                nome: 'Clínica',
                tipo: 'Clínica',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 1,
                nome: 'Profissional',
                tipo: 'Profissional',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 1,
                nome: 'Paciente',
                tipo: 'Paciente',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
        ];
        /* let qtd = Math.floor(Math.random() * 20);

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    nome: 'Grupo ' + i+1,
                    tipo: 'Administrador',
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        } */
        return data;

    }
    
}
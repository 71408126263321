export class PageConfig {

    data(){

        let data = [];
        let qtd = Math.floor(Math.random() * 20);

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    avatar: 'assets/images/avatars/photo.jpg',
                    usuario: 'Leandro Melo',
                    acao: 'Lorem ipsum dollor Lorem ipsum dollor Lorem ipsum dollor Lorem ipsum dollor Lorem ipsum dollor Lorem ipsum dollor',
                    data: '12/02/2021 as 14:44'
                },
            );
        
        }
        return data;

    }
    
}
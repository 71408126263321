import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
@Component({
  selector: 'vex-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  constructor() { }
  @Input() title: string;
  @Input() crumbs: string[];

  icRefresh = icRefresh;

  ngOnInit(): void {
  }

}

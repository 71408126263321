<div class="card mt-6">
    
    <div class="px-gutter py-4 border-b">
      <h2 class="title m-0">Consultórios</h2>
    </div>

    <vex-data-table
      [title]="title"
      [selection]="selection"
      [data]="dataSource"
      [columns]="columns"
      [config]="configuration"
      [createShow]="true"

      (refresh)="onRefresh($event)"
      (search)="onSearch($event)"
    ></vex-data-table>
</div>
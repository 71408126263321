import { Component, OnInit } from '@angular/core';
import { ViewInterface } from 'app/shared/interfaces/view.interface';
import { Operation } from 'app/shared/enums/operation';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { Config } from 'app/shared/components/several-components/data-table/config';
import { MatDialog } from '@angular/material/dialog';

import icView from '@iconify/icons-ic/twotone-visibility';
import icUpdate from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete-forever';
import icSearch from '@iconify/icons-ic/twotone-search';

import icPhone from '@iconify/icons-ic/twotone-phone';
import icWork from '@iconify/icons-ic/twotone-work';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icWhatshot from '@iconify/icons-ic/twotone-whatshot';
import icMail from '@iconify/icons-ic/twotone-mail';
import icAdd from '@iconify/icons-ic/twotone-add';

import { TranslateService } from '@ngx-translate/core';

import { GlobalService } from 'app/shared/services/global.service';
import { Router } from '@angular/router';
import { ToastService } from 'app/shared/services/toast.service';
import { SwalService } from 'app/shared/services/swal.service';

import * as PageConfigSobre from 'app/mockups/page-config-sobre';
import * as PageConfigFormacao from 'app/mockups/page-config-formacao';
import * as PageConfigExperiencias from 'app/mockups/page-config-experiencias';
import * as PageConfigCertificados from 'app/mockups/page-config-certificados';
import * as PageConfigIdiomas from 'app/mockups/page-config-idiomas';

import { Link } from '@vex/interfaces/link.interface';
import { User } from 'app/shared/models/user.model';
import { Store, select } from '@ngrx/store';
import { Auth } from 'app/shared/models/auth.model';

import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';

@Component({
  selector: 'vex-app-view-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class ViewAboutComponent implements OnInit {
  constructor(
    private _translate: TranslateService,
    private _router: Router,
    public globalService: GlobalService,
    private _toastService: ToastService,
    private _swalService: SwalService,
    private dialog: MatDialog,
    private _store: Store<{ auth: Auth }>,
  ) { 
    this._store.pipe(select('auth')).subscribe(data => {
      this.user = data.user;
    });
  }

  title = 'Meu Perfil';
  icSearch = icSearch;
  icPhone = icPhone;
  icWork = icWork;
  icAccessTime = icAccessTime;
  icWhatshot = icWhatshot;
  icMail = icMail;
  icAdd = icAdd;

  links: Link[] = [
    {
      label: 'SOBRE',
      route: './',
      disabled: false,
      routerLinkActiveOptions: { exact: true }
    },
    {
      label: 'FOTOS',
      route: './photos',
      disabled: false
    },
    {
      label: 'CONSULTÓRIOS',
      route: './consultations',
      disabled: false
    }
  ];

  user: User;

  icView = icView;
  icUpdate = icUpdate;
  icDelete = icDelete;

  operation: Operation;

  telefones = [];
  emails = [];
  sociais = [];

  paginationInitial = { page: 1, limit: 10 };
  options = {};

  //TABLE FORMACAO
  selectionFormacao = new SelectionModel<any>(true, []);
  configurationFormacao = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  dataSourceFormacao: any = [];
  dataSourceBaseFormacao: any = [];

  columnsFormacao: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: 'Curso', property: 'curso', type: 'text', visible: true },
    { label: 'Descrição', property: 'descricao', type: 'text', visible: true },
    { label: 'Ano', property: 'ano', type: 'badge', visible: true, cssClasses: ['text-green', 'bg-green-light'] },
    { label: null, labelFilter: 'Ações', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  //TABLE EXPERIENCIAS
  selectionExperiencias = new SelectionModel<any>(true, []);
  configurationExperiencias = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  dataSourceExperiencias: any = [];
  dataSourceBaseExperiencias: any = [];

  columnsExperiencias: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: 'Título', property: 'titulo', type: 'text', visible: true },
    { label: 'Descrição', property: 'descricao', type: 'text', visible: true },
    { label: 'Ano', property: 'ano', type: 'badge', visible: true, cssClasses: ['text-green', 'bg-green-light'] },
    { label: null, labelFilter: 'Ações', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  //TABLE CERTIFICADOS
  selectionCertificados = new SelectionModel<any>(true, []);
  configurationCertificados = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  dataSourceCertificados: any = [];
  dataSourceBaseCertificados: any = [];

  columnsCertificados: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: 'Nome', property: 'nome', type: 'text', visible: true },
    { label: 'Instituição', property: 'instituicao', type: 'text', visible: true },
    { label: 'Ano', property: 'ano', type: 'badge', visible: true, cssClasses: ['text-green', 'bg-green-light'] },
    { label: null, labelFilter: 'Ações', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  //TABLE IDIOMAS
  selectionIdiomas = new SelectionModel<any>(true, []);
  configurationIdiomas = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  dataSourceIdiomas: any = [];
  dataSourceBaseIdiomas: any = [];

  columnsIdiomas: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: 'Idioma', property: 'idioma', type: 'text', visible: true },
    { label: 'Ano', property: 'ano', type: 'badge', visible: true, cssClasses: ['text-green', 'bg-green-light'] },
    { label: null, labelFilter: 'Ações', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  ngOnInit(): void {
    console.log('user', this.user);
    this.onRefresh();
  }

  onRefresh(event?): void {
    this.telefones = new PageConfigSobre.PageConfig().dataTelefones();
    this.emails = new PageConfigSobre.PageConfig().dataEmails();
    this.sociais = new PageConfigSobre.PageConfig().dataSociais();

    // formacao
    this.dataSourceFormacao = [];
    this.dataSourceFormacao = new PageConfigFormacao.PageConfig().data();
    this.dataSourceBaseFormacao = this.dataSourceFormacao;
    this.configurationFormacao.total = this.dataSourceFormacao.length;

    // experiencias
    this.dataSourceExperiencias = [];
    this.dataSourceExperiencias = new PageConfigExperiencias.PageConfig().data();
    this.dataSourceBaseExperiencias = this.dataSourceExperiencias;
    this.configurationExperiencias.total = this.dataSourceExperiencias.length;

    // certificados
    this.dataSourceCertificados = [];
    this.dataSourceCertificados = new PageConfigCertificados.PageConfig().data();
    this.dataSourceBaseCertificados = this.dataSourceCertificados;
    this.configurationCertificados.total = this.dataSourceCertificados.length;

    // idiomas
    this.dataSourceIdiomas = [];
    this.dataSourceIdiomas = new PageConfigIdiomas.PageConfig().data();
    this.dataSourceBaseIdiomas = this.dataSourceIdiomas;
    this.configurationIdiomas.total = this.dataSourceIdiomas.length;
  }

  onCreate(): void {}

  onClickRow(row): void {}

  onSearch(search: string) {}

  onDelete(row) {
    this._swalService.confirm(`Excluir registro #${row.id}`, 'Tem certeza que deseja excluir este registro?')
    .then((res) => {
      if(res.value){
        this._toastService.success('O registro foi excluído!');
      }
    })
  }

  getAction(event){
    switch (event.action) {
      case 'delete': 
        this.onDelete(event.row);
        break;

      default:
        true;
    }
  }

  onToggleStatus(row): void { }
}

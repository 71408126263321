export class PageConfig {

    data(rand: boolean = false){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                id_area: [1],
                descricao: 'Clínica Geral',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 2,
                id_area: [1],
                descricao: 'Cirurgia e Traumatologia Buco – Maxilo – Facial',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 3,
                id_area: [1],
                descricao: 'Dentística',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 4,
                id_area: [1],
                descricao: 'Disfunção Têmporo Mandibular e Dor Orofacial',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 5,
                id_area: [1],
                descricao: 'Endodontia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 6,
                id_area: [1],
                descricao: 'Estomatologia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 7,
                id_area: [1],
                descricao: 'Implantodontia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 8,
                id_area: [1],
                descricao: 'Odontogeriatria',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 9,
                id_area: [1],
                descricao: 'Odontologia do Trabalho',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 10,
                id_area: [1],
                descricao: 'Odontologia Legal',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 11,
                id_area: [1],
                descricao: 'Odontologia para Pacientes com Necessidades Especiais',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 12,
                id_area: [1],
                descricao: 'Odontopediatria',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 13,
                id_area: [1],
                descricao: 'Ortodontia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 14,
                id_area: [1],
                descricao: 'Ortopedia Funcional dos Maxilares',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 15,
                id_area: [1],
                descricao: 'Patologia Bucal',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 16,
                id_area: [1],
                descricao: 'Periodontia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 17,
                id_area: [1],
                descricao: 'Prótese Buco – Maxilo – Facial',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 18,
                id_area: [1],
                descricao: 'Prótese Dentária',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 19,
                id_area: [1],
                descricao: 'Radiologia Odontológica e Imaginologia',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 20,
                id_area: [1],
                descricao: 'Saúde Coletiva',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
        ];

        return data;

    }
    
}
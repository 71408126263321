import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "profile",
  },

  {
    path: "profile",
    loadChildren: () =>
      import("./profile/module").then((m) => m.ProfileModule),
  },

  {
    path: "schedule",
    loadChildren: () =>
      import("./schedule/calendar.module").then((m) => m.ScheduleModule),
  },

  {
    path: "areas",
    loadChildren: () =>
      import("./areas/module").then((m) => m.AreasModule),
  },
  {
    path: "specialties",
    loadChildren: () =>
      import("./specialties/module").then((m) => m.SpecialtiesModule),
  },
  {
    path: "procedures",
    loadChildren: () =>
      import("./procedures/module").then((m) => m.ProceduresModule),
  },
  {
    path: "qualifications",
    loadChildren: () =>
      import("./qualifications/module").then((m) => m.QualificationsModule),
  },
  {
    path: "payment-methods",
    loadChildren: () =>
      import("./payment-methods/module").then((m) => m.PaymentsMethodsModule),
  },
  {
    path: "covenants",
    loadChildren: () =>
      import("./covenants/module").then((m) => m.CovenantsModule),
  },

  // Modelos
  
  /* {
    path: "customers",
    loadChildren: () =>
      import("./customers/customers.module").then((m) => m.CustomersModule),
  },
  {
    path: "contracts",
    loadChildren: () =>
      import("./contracts/contracts.module").then((m) => m.ContractsModule),
  },
  {
    path: "service-orders",
    loadChildren: () =>
      import("./service-orders/service-orders.module").then((m) => m.ServiceOrdersModule),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./products/products.module").then((m) => m.ProductsModule),
  },
  {
    path: "pests",
    loadChildren: () =>
      import("./pests/pests.module").then((m) => m.PestsModule),
  }, */

  {
    path: "profiles",
    loadChildren: () =>
      import("./profiles/profiles.module").then((m) => m.ProfilesModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "audit",
    loadChildren: () =>
      import("./audit/audit.module").then((m) => m.AuditModule),
  },

  /* {
    path: "",
    pathMatch: "full",
    redirectTo: "dashboard",
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "acl",
    loadChildren: () => import("./acl/acl.module").then((m) => m.AclModule),
  },
  {
    path: "menu-groups",
    loadChildren: () =>
      import("./menu-groups/menu-groups.module").then(
        (m) => m.MenuGroupsModule
      ),
  },
  {
    path: "menu-itens",
    loadChildren: () =>
      import("./menu-itens/menu-itens.module").then((m) => m.MenuItensModule),
  },
  {
    path: "help-center",
    loadChildren: () =>
      import("./help-center/help-center.module").then(
        (m) => m.HelpCenterModule
      ),
  },
  {
    path: "accounts",
    loadChildren: () =>
      import("./accounts/accounts.module").then((m) => m.AccountModule),
  },
  {
    path: "parameters",
    loadChildren: () =>
      import("./parameters/parameters.module").then((m) => m.ParametersModule),
  },
  {
    path: "file-manager",
    loadChildren: () =>
      import("./file-manager/file-manager.module").then(
        (m) => m.FileManagerModule
      ),
  },
  {
    path: "social-profile",
    loadChildren: () =>
      import("./social/social.module").then((m) => m.SocialModule),
  }, */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

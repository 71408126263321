import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

declare const Notyf: any;

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    notyf = new Notyf({
        ripple: true,
        position: {x:'right', y:'top'},
        dismissible: true
    });

    constructor(
        private translate: TranslateService
    ) { }

    success(message: string): void {
        let alert = (message) ? this.notyf.success(`Sucesso! ${message}`) : this.notyf.success(`Sucesso! Nossa base de dados foi atualizada`) ;
        return alert;
    }

    error(message: string): void {
        let alert = (message) ? this.notyf.error(`Ooops! ${message}`) : this.notyf.error(`Ooops! Algo de errado aconteceu, tente novamente`) ;
        return alert;
    }

    info(message: string): void {
        let alert = (message) ? this.notyf.success(message) : this.notyf.success(`Ooops! Algo de errado aconteceu, tente novamente`) ;
        return alert;
    }
}

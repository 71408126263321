import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { TranslateService } from '@ngx-translate/core';
import faFacebook from '@iconify/icons-fa-brands/facebook-f';
import faGoogle from '@iconify/icons-fa-brands/google';
import faLinkedin from '@iconify/icons-fa-brands/linkedin';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Auth } from 'app/shared/models/auth.model';
import { LoginService } from 'app/shared/services/login.service';
import { AddAuthData } from 'app/store/authentication/actions';
import { SwalService } from 'app/shared/services/swal.service';
import { DefaultParams } from 'app/shared/enums/default';
import { colorVariables } from '@vex/components/config-panel/color-variables';
import { ToastService } from 'app/shared/services/toast.service';
import { GlobalService } from 'app/shared/services/global.service';

@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ]
})

export class LoginComponent implements OnInit {

  form: FormGroup;

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faLinkedin = faLinkedin;

  authData: Observable<Auth>;
  subscription: Subscription;
  colorVariables = colorVariables;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    public translate: TranslateService,
    private _router: Router,
    private _store: Store<{ auth: Auth }>,
    private _loginService: LoginService,
    private _swalService: SwalService,
    private _toastService: ToastService,
    public globalService: GlobalService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, Validators.required),
    });
  }

  onLogin() {
    // show loader bar
    this.globalService.onProcessForm = !this.globalService.onProcessForm;

    /* const dataUser = {
      login: this.form.controls.user.value,
      email: 'ti@oficina5.com.br',
      username: 'Tech Solution',
      urlPhoto: 'assets/images/logos/icon.png'
    }

    sessionStorage.setItem('dataUser', JSON.stringify(dataUser));
    await this.globalService.sleep(2000);

    this.globalService.onProcessForm = !this.globalService.onProcessForm;
    this._router.navigate(['auth/']); */

    this.subscription = this._loginService.auth(this.form.value).subscribe(
      (res: Auth) => {
        this.globalService.onProcessForm = !this.globalService.onProcessForm;

        const auth  = res;
        auth.user.urlPhoto = auth.user.urlPhoto || 'assets/images/avatars/photo.jpg';
        this._store.dispatch(AddAuthData(auth));
        this._router.navigate(['auth/']);

      }, (error) => {
        this.globalService.onProcessForm = !this.globalService.onProcessForm;
        this._toastService.error(error.error.message);
      }
    );
  }

  loginWithFacebook(): void {

  }

  loginWithGoogle(): void {

  }

  loginWithLinkedin(): void {
    
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

}

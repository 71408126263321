export class PageConfig {

    // telefones
    dataTelefones(){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                telefone: '(34) 00000-0000',
                tipo: 'Pessoal',
                principal: true,
            }
        ];

        return data;

    }

    // emails
    dataEmails(){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                email: 'usuario@host.com.br',
                tipo: 'Pessoal',
                principal: true,
            }
        ];

        return data;

    }

    // redes sociais
    dataSociais(){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                rede: 'Facebook',
                url: 'https://www.facebook.com/larner.diogo',
                tipo: 'Comercial',
                principal: true,
            }
        ];

        return data;

    }
    
}
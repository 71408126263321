import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'not-auth',
        loadChildren: () => import('./modules/not-auth/not-auth.module').then(m => m.NotAuthModule)
      },
      {
        path: 'auth',
        canActivate: [AuthGuard],
        component: CustomLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
          }
        ]
      },
      {
        path: '**',
        redirectTo: 'auth'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    useHash: true,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {

  @Input() formGroup;
  @Input() formcontrolname;
  control: FormControl;
  constructor() { }

  ngOnInit(): void {
    this.formGroup.get(this.formcontrolname);
    this.control = this.formGroup.get(this.formcontrolname);
  }

}

<div class="footer" fxLayout="row" 
style="position: fixed; bottom: 0; width: 100%;">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-sm>
      © 2020 Desenvolvido por 
      <a mat-button color="primary" class="p-1" 
      [matTooltip]="'Visitar site'" [href]="'http://oficina5.com.br'" target="_blank">Oficina5</a>
      - Direitos reservados para Tec Solution
    </div>
  </div>
</div>

<mat-form-field [formGroup]="formGroup" fxFlex="100">

  <mat-label>{{ label }}</mat-label>

  <mat-date-range-input [rangePicker]="picker" [required]="checkRequired(formcontrolnameStart, formcontrolnameEnd)">
    <input matStartDate [readonly]="readonly" [formControlName]="formcontrolnameStart" [placeholder]="placeholderStart"
      [min]="minDate" autocomplete="off" (click)="picker.open()">
    <input matEndDate [readonly]="readonly" [formControlName]="formcontrolnameEnd" [placeholder]="placeholderEnd"
      [max]="maxDate" autocomplete="off" (click)="picker.open()">
  </mat-date-range-input>

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-error>
    <mat-error
      *ngIf="formGroup.get(formcontrolnameStart).hasError('required') || formGroup.get(formcontrolnameEnd).hasError('required')">
      Dados obrigatórios não informados</mat-error>
    <mat-error *ngIf="formGroup.get(formcontrolnameStart).hasError('matStartDateInvalid')">Data Inicial Inválida
    </mat-error>
    <mat-error *ngIf="formGroup.get(formcontrolnameEnd).hasError('matEndDateInvalid')">Data Final Inválida</mat-error>
  </mat-error>

</mat-form-field>
<mat-form-field [formGroup]="formGroup" fxFlex="100">
  <mat-label>{{ label }} {{ checkRequired() && '*' }}</mat-label>
  <textarea #input matTextareaAutosize [matAutosizeMinRows]="matAutosizeMinRows"
    [matAutosizeMaxRows]="matAutosizeMaxRows" type="text" matInput [formControlName]="formcontrolname"
    [placeholder]="placeholder" autocomplete="off"
    (keyup)="this.formGroup.get(this.formcontrolname).setValue(onChange($event.target.value))"></textarea>
  <mat-hint align="end" *ngIf="maxLength">{{input.value?.length || 0}}/{{ maxLength }}</mat-hint>
  <mat-error>
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>
</mat-form-field>
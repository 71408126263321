import { Component, OnInit, Input, Output, ChangeDetectorRef, OnChanges, OnDestroy, ViewChild, AfterViewInit, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import icAdd from '@iconify/icons-ic/twotone-add';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAddCircle from '@iconify/icons-ic/twotone-add-circle';
import icRemoveCircle from '@iconify/icons-ic/twotone-remove-circle';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icEmptyList from '@iconify/icons-ic/twotone-cancel-presentation';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icColor from '@iconify/icons-ic/twotone-lens';

import icStar from '@iconify/icons-ic/twotone-star';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';

import icGridOn from '@iconify/icons-ic/twotone-grid-on';
import icGridOff from '@iconify/icons-ic/twotone-grid-off';

import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { DefaultParams } from 'app/shared/enums/default';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Config } from './config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from 'app/shared/services/utils.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectionListChange } from '@angular/material/list';
import * as _ from 'lodash';
import { isArray, isEmpty } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'vex-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  constructor(
    private _changesDetector: ChangeDetectorRef,
    public translate: TranslateService,
    private _utilsService: UtilsService,

  ) {
    this._utilsService.paginatorWasChanged.subscribe(() => {
      this.pageIndex = 0;
    });
  }
  searchCtrl = new FormControl();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() data: Array<any> = [];
  @Input() startPageSize = 10;
  @Input() config: Config;
  @Input() selection: SelectionModel<any>;
  @Input() columns: TableColumn<any>[] = [];
  @Input() title: string;
  @Input() headerShow = true;
  @Input() createShow: boolean = true;
  @Input() typeViewShow: boolean = false;
  @Input() starredShow: boolean = false;
  @Input() disablePagination = false;

  @Input() typeView: string = 'grid' || 'list';

  @Output() refresh = new EventEmitter();
  @Output() clickRow = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() create = new EventEmitter();
  @Output() send = new EventEmitter();
  @Output() changeView = new EventEmitter();
  @Output() starred = new EventEmitter();
  @Output() counter = new EventEmitter();

  starredIcon: any = icStarBorder;
  starredType = 'no-star';

  dataSource: MatTableDataSource<{
    actions?: {
      title: string,
      icon: any,
      function: (row: any) => {}
    }
  }>;
  actualDirection: string;
  actualActive: string;

  // Icons

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icAdd = icAdd;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAddCircle = icAddCircle;
  icRemoveCircle = icRemoveCircle;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icMoreVert = icMoreVert;
  icFolder = icFolder;
  icEmptyList = icEmptyList;
  icRefresh = icRefresh;
  icColor = icColor;

  icStar = icStar;
  icStarBorder = icStarBorder;

  icGridOn = icGridOn;
  icGridOff = icGridOff;
  //

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 50, 100, 300];


  public dataFilterCtrl: { [key: string]: FormControl } = {};

  public filteredData: { [key: string]: ReplaySubject<any[]> } = {};

  DEFAULT_AVATAR = DefaultParams.DEFAULT_AVATAR;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.pageSize = this.startPageSize;
    this.pageSizeOptions = [this.startPageSize];
    if (this.searchCtrl) {
      this.searchCtrl.valueChanges.pipe(debounceTime(500)).subscribe(value => this.search.emit(value));
    }
    this._start();

  }

  ngOnChanges(): void {
    this._start();
    this.verifySelectRows();
  }

  verifySelectRows() {
    const selectionButtons = this.config.options.selectionButtons;
    if (selectionButtons !== undefined && isEmpty(selectionButtons)) {
      this.columns = this.columns.filter( x => x.type !== 'checkbox');
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  getDataFilterCtrl(key: string) {
    if (!this.dataFilterCtrl[key]) {
      this.dataFilterCtrl[key] = new FormControl();
      this.filteredData[key] = new ReplaySubject<any[]>(1);
      this.dataFilterCtrl[key].valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
        this.filterData(key);
        this._changesDetector.detectChanges();
      });
      this.filterData(key);
      this._changesDetector.detectChanges();
    }
    return this.dataFilterCtrl[key];
  }

  protected filterData(column: string): void {
    const data = this.config.options.labels[column];
    if (!data) { return; }

    let search = this.dataFilterCtrl[column].value;
    if (!search) {
      this.filteredData[column].next(data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredData[column].next(
      data.filter(item => item.text.toLowerCase().includes(search))
    );

    this._changesDetector.detectChanges();
  }

  private _start(): void {
    if (this.data) {
      this.dataSource = new MatTableDataSource(this.data);
      this._changesDetector.detectChanges();
      this._startPaginator();
      
    }
  }

  public sortChange(sort: Sort): void {
    let canEmit = false;

    if ((this.actualDirection !== sort.direction) || (this.actualActive !== sort.active)) {
      canEmit = true;
    }

    const direction = sort.direction.toUpperCase();
    const active = sort.active;
    this.actualDirection = direction;
    this.actualActive = active;
    if (canEmit) {
      const params = {
        sort: direction ? `${active},${direction}` : ''
      };
      this.onRefresh(params);
    }

  }

  private _startPaginator(): void {
    if (this.config && this.config.total) {
      this.length = this.config.total;
      if (this.length > this.startPageSize) {
        if (this.startPageSize !== 10) {
          this.pageSizeOptions = [this.startPageSize, 50, 100, 300];
        } else {
          this.pageSizeOptions = [10, 50, 100, 300];
        }
      }
    }

    if (this.paginator) {
      this.paginator.page.pipe(takeUntil(this._destroy$)).subscribe(() => {
        let canEmit = false;

        if ((this.pageSize !== this.paginator.pageSize) || (this.pageIndex !== this.paginator.pageIndex)) {
          canEmit = true;
        }

        this.pageSize = this.paginator.pageSize;
        this.pageIndex = this.paginator.pageIndex;

        if (canEmit) {
          this.onRefresh(
            {
              page: this.paginator.pageIndex + 1,
              limit: this.paginator.pageSize
            }
          );
        }
      }
      );
    }
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  onChangeView(type){
    (type === 'list') ? this.typeView = 'grid' : this.typeView = 'list';
    this.changeView.emit(type);
  }

  onRefresh(params = {}) {
    this.selection.clear();
    this.refresh.emit(params);
  }

  console(x) {
    console.log(x);
  }

  onClickRow(row: any) {
    this.clickRow.emit(row);
  }

  onCreate() {
    this.create.emit();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column: any, event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(event) {
    if (event.checked) {
      this.dataSource.data.forEach(row => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }


  isButtonToggle(column: string): boolean {
    if (this.config.options.toggleButtons) {
      const func = this.config.options.toggleButtons[column];
      return !!!func;
    }
    return true;
  }

  toggleButtonFunction(event: MatSlideToggleChange, column: string, row: any) {
    const func = this.config.options.toggleButtons[column];
    if (func) {
      return func(row);
    }
    return event.checked = !event.checked;
  }

  getButtonIcon(column: string): boolean {
    if (this.config.options.buttons) {
      const button = this.config.options.buttons[column];
      return button.icon;
    }
    return true;
  }

  buttonFunction(event: MatSlideToggleChange, column: string, row: any) {
    const func = this.config.options.buttons[column].function;
    if (func) {
      return func(row);
    }
  }

  sendFunction(action, row){
    this.send.emit({action: action, row: row});
  }

  getCurrentLabels(column: string, row: any) {
    const labels = this.config.options.labels[column];
    const currentLabels: Array<any> = isArray(row[column]) ? row[column].map(x => x.id) : [];
    return _.filter(labels, x => {
      return currentLabels.includes(x.id);
    });
  }

  onLabelChangeFunction(event: MatSelectionListChange, column: string, row: any) {
    const func = this.config.options.onLabelChange[column];
    if (func) {
      return func(event, row);
    }
  }

  onStarred(){
    if(this.starredType === 'star'){
      this.starredType = 'no-star';
      this.starredIcon = icStarBorder;
      this.starred.emit('no-star');
    
    }else{
      this.starredType = 'star';
      this.starredIcon = icStar;
      this.starred.emit('star');
    }
  }

  onAddCounter(row){
    row.counter++;
    this.counter.emit(row);
  }

  onDelCounter(row){
    (row.counter === 0) ? row.counter : row.counter--;
    this.counter.emit(row);
  }


}

export enum ConfigName {
  /* apollo = 'vex-layout-apollo',
  zeus = 'vex-layout-zeus',
  hermes = 'vex-layout-hermes',
  poseidon = 'vex-layout-poseidon',
  ares = 'vex-layout-ares',
  ikaros = 'vex-layout-ikaros', */

  apollo = 'vex-layout-ares',
  zeus = 'vex-layout-ares',
  hermes = 'vex-layout-ares',
  poseidon = 'vex-layout-ares',
  ares = 'vex-layout-ares',
  ikaros = 'vex-layout-ares',
}

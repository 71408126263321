import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { TranslateService } from '@ngx-translate/core';
import { ParametersService } from './shared/services/parameters.service';
import { LoaderThemeService } from './shared/services/loader-theme.service';
import { NavigationItem } from '@vex/interfaces/navigation-item.interface';
import { LoaderMenuService } from './shared/services/loader-menus.service';
import * as arrayToTree from 'array-to-tree';
@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private parametersService: ParametersService,
    public translate: TranslateService,
    private loaderTheme: LoaderThemeService,
    private loaderMenus: LoaderMenuService,
  ) {

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    translate.addLangs(['pt-br', 'en', 'es']);
    const defaultLang = localStorage.getItem('language') || 'pt-br';
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang.match(/pt-br|en|es/) ? defaultLang : 'pt-br');

    // CARREGA O LAYOUT DO BANCO DE DADOS
    this.loaderTheme.boot();
    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */


    // this.configService.updateConfig({
    //   imgSrc: 'assets/images/logos/logo-square.svg',
    //   sidenav: {
    //     title: 'Admin',
    //     imageUrl: 'assets/images/logos/logo-square.svg',
    //     showCollapsePin: false
    //   },
    //   footer: {
    //     visible: false
    //   }
    // });

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    this.loaderMenus.boot();
    // this.loaderMenus.default();
  }
}

import { Component, OnInit } from '@angular/core';
import { ViewInterface } from 'app/shared/interfaces/view.interface';
import { Operation } from 'app/shared/enums/operation';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { Config } from 'app/shared/components/several-components/data-table/config';
import { MatDialog } from '@angular/material/dialog';

import icAdd from '@iconify/icons-ic/twotone-add';

import { TranslateService } from '@ngx-translate/core';

import { GlobalService } from 'app/shared/services/global.service';
import { Router } from '@angular/router';
import { ToastService } from 'app/shared/services/toast.service';
import { SwalService } from 'app/shared/services/swal.service';

import * as PageConfigServicos from 'app/mockups/page-config-servicos';
import { Link } from '@vex/interfaces/link.interface';
import { User } from 'app/shared/models/user.model';
import { Store, select } from '@ngrx/store';
import { Auth } from 'app/shared/models/auth.model';

import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';

@Component({
  selector: 'vex-app-view-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class ViewPhotosComponent implements OnInit {
  constructor(
    private _translate: TranslateService,
    private _router: Router,
    public globalService: GlobalService,
    private _toastService: ToastService,
    private _swalService: SwalService,
    private dialog: MatDialog,
    private _store: Store<{ auth: Auth }>,
  ) { 
    this._store.pipe(select('auth')).subscribe(data => {
      this.user = data.user;
    });
  }

  icAdd = icAdd;

  user: User;
  dataSource: any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];

  ngOnInit(): void {
    console.log('user', this.user);
    this.onRefresh();
  }

  onRefresh(event?): void {}
}

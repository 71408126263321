import { Action } from '@ngrx/store';
import { NotificationInterface } from 'app/shared/interfaces/notification.interface';

export enum ActionTypes {
    SET = '[notification] ADD',
    CLEAR = '[notification] CLEANNING',
}

export const SetNotificationData = (authInterface: NotificationInterface) => {
    return { type: ActionTypes.SET, payload: authInterface } as Action;
};

export const ClearNotificationData = () => {
    return { type: ActionTypes.CLEAR, payload: null } as Action;
};

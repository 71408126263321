<div class="w-full h-full bg-wallpaper" fxLayout="column" fxLayoutAlign="center center">
  
  <div @fadeInUp class="card overflow-hidden w-full max-w-xs" style="position: relative;">
    <div class="p-6 pb-0" fxLayout="column" fxLayoutAlign="center center">
      <div class="fill-current text-center mt-4">
        <img class="w-30" src="assets/images/logos/logo.svg">
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">{{ "NOTAUTH.FORGOT_PASSWORD.TITLE" | translate }}</h2>
      <h4 class="body-2 text-secondary m-0">{{ "NOTAUTH.FORGOT_PASSWORD.SUBTITLE" | translate }}</h4>
    </div>

    <div [formGroup]="form" class="p-6 flex flex-col">
      <mat-form-field>
        <mat-label>{{ "LABEL.EMAIL" | translate }}</mat-label>

        <mat-icon [icIcon]="icMail" class="mr-2" matPrefix></mat-icon>
        <input formControlName="email" matInput required>
        <mat-error *ngIf="form.get('email').hasError('required')">
          {{ "NOTAUTH.FORGOT_PASSWORD.ERROR.WE_CAN_T_RECOVER_YOUR_PASSWORD_WITHOUT_YOUR_EMAIL" | translate }}
        </mat-error>
      </mat-form-field>

      <button (click)="send()" class="mt-2" [disabled]="form.invalid || globalService.onProcessForm" 
      color="primary" mat-raised-button type="button">
        {{ "NOTAUTH.FORGOT_PASSWORD.BUTTON.SEND_RECOVERY_LINK" | translate }}
      </button>

      <button class="mt-3" mat-button [disabled]="globalService.onProcessForm" 
      [ngStyle]="{'backgroundColor' : 'white', 'color': colorVariables.blue.default}" [routerLink]="['/not-auth/login']" type="button">
        {{ 'NOTAUTH.FORGOT_PASSWORD.BUTTON.GO_BACK' | translate }}
      </button>
    </div>

    <mat-progress-bar mode="indeterminate" class="progress-bar-bottom"
    *ngIf="globalService.onProcessForm"></mat-progress-bar>
  </div>

</div>

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Operation } from 'app/shared/enums/operation';

import { GlobalService } from 'app/shared/services/global.service';

import icClose from '@iconify/icons-ic/twotone-close';
import icSave from '@iconify/icons-ic/twotone-save';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';

import icDone from '@iconify/icons-ic/twotone-done';
import { ToastService } from 'app/shared/services/toast.service';
import { SwalService } from 'app/shared/services/swal.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Config } from 'app/shared/components/several-components/data-table/config';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { PageConfig } from './page-config';

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  constructor(
    public translate: TranslateService,
    private _route: ActivatedRoute,
    public globalService: GlobalService,
    private _toastService: ToastService,
    private _swalService: SwalService
  ) { }

  title = 'Perfil de Usuários';
  action;
  operation: Operation;

  formStepper1: FormGroup;

  id: number;
  item: any;
  subscription: Subscription;

  icClose = icClose;
  icSave = icSave;
  icEdit = icEdit;
  icDelete = icDelete;

  icDone = icDone;

  //TABLE
  paginationInitial = { page: 1, limit: 10 };
  options = {};

  selection = new SelectionModel<any>(true, []);
  configuration = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  //TABLE USUARIOS
  dataSourceUsuarios: any = [];
  dataSourceBaseUsuarios: any = [];

  columnsUsuarios: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: '', property: 'avatar', type: 'image', visible: true },

    { label: 'Nome', property: 'nome', type: 'text', visible: true },
    { label: 'Usuário', property: 'usuario', type: 'text', visible: true },

    { label: '', property: 'perfil', type: 'badge', visible: true, cssClasses: ['text-green', 'bg-green-light'] },

    { label: '', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  ngOnInit(): void {
    this.createForm();
    this.fillForm();
    this.onRefresh();

    this._route.params.subscribe(params => {

      if(params.id === 'new'){
        this.action = 'Inserindo';
        this.operation = Operation.NEW;
      
      }else{
        this.id = params.id;
        
        this.action = 'Visualizando';
        this.operation = Operation.VIEW;

        this.formStepper1.disable();
      }

    });
  }

  onRefresh(event?): void {
    this.dataSourceBaseUsuarios = [];

    this.dataSourceUsuarios = new PageConfig().dataUsuarios();
    this.dataSourceBaseUsuarios = this.dataSourceUsuarios;

    this.configuration.total = this.dataSourceBaseUsuarios.length;
  }

  createForm(): void {
    this.formStepper1 = new FormGroup({
      campo1: new FormControl(null, Validators.required),
    });
  }

  fillForm(): void { }

  onEdit(){
    this.action = 'Editando';
    this.operation = Operation.EDIT;

    this.formStepper1.enable();
  }


  onSave(): void {
    this._toastService.success('O registro foi salvo na base de dados!');
    this.onClose();
  }

  onDelete(item?): void {
    this._swalService.confirm(`Excluir registro #${(item) ? item.id : this.id}`, 'Tem certeza que deseja excluir este registro?')
    .then((res) => {
      if(res.value){
        this._toastService.success('O registro foi excluído!');
        this.onClose();
      }
    })    
  }

  onClose(): void {
    this.formStepper1.reset();
    this.globalService.backBtn();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onToggleStatus(row): void { }

  onSearch(search: string, type) {

    switch (type) {
      case 'usuarios': 
        this.dataSourceUsuarios = this.globalService.searchLocal(search, this.dataSourceBaseUsuarios);
        break;

      default:
        true;
    }
  }

  getAction(event):void {
    switch (event.action) {
      case 'delete': 
        this.onDelete(event.row);
        break;

      default:
        true;
    }
  }

}

<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
  <button (click)="close()"
          class="ltr:right-12 rtl:left-12 top-12 absolute"
          color="primary"
          mat-icon-button
          type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>

  <input #searchInput (keyup.enter)="search()" [formControl]="searchCtrl" class="search-input" [placeholder]="('COMMON.SEARCH' | translate) + '...' "/>
  <div class="search-hint">{{ 'COMMON.HIT_ENTER_TO_SEARCH' | translate }}</div>
</div>

<div (click)="close()" *ngIf="show$ | async" class="search-overlay"></div>


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Store, select } from '@ngrx/store';
import { Auth } from 'app/shared/models/auth.model';
import { User } from 'app/shared/models/user.model';
declare const jsPDF: any;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportDataService {

    authUser: User;
    observ = new Subject();

    constructor(
        private _httpClient: HttpClient,
        private _store: Store<{ auth: Auth }>,
    ) {
        const authData = this._store.pipe(select('auth'));

        authData.subscribe(data => {
            this.authUser = data.user;
        });
    }

    public exportAsExcelFile(columns: any[], data: any[], excelFileName: string): void {
        const formatedData = this.formatXLSData(columns, data);

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formatedData);
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);
    }

    public formatXLSData(columns: string | any[], data: string | any[]) {
        const rowsXLSX = [];

        for (let index = 0; index < data.length; index++) {
            const objXLSX = {};
            for (let i = 0; i < columns.length; i++) {
                objXLSX[columns[i].title] = data[index][columns[i].dataKey];
            }
            rowsXLSX.push(objXLSX);
        }

        return rowsXLSX;
    }

    public formatPDFData(columns: string | any[], data: string | any[]) {
        const rowsPDF = [];

        for (let index = 0; index < data.length; index++) {
            const objPDF = {};
            for (let i = 0; i < columns.length; i++) {
                objPDF[columns[i].dataKey] = data[index][columns[i].dataKey];
            }
            rowsPDF.push(objPDF);
        }

        return rowsPDF;
    }

    exportAsPDF(fileName: string, title: string, columns, data: any[], print: boolean) {
        let imgData: string | ArrayBuffer;
        const self = this;
        const doc = new jsPDF('l', 'pt', 'a4', true);
        const rows = this.formatPDFData(columns, data);

        this._httpClient.get('./assets/images/logos/agrocrm-logo.png', { responseType: 'blob' })
            .subscribe(result => {
                const reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = () => {
                    imgData = reader.result;
                    const dateTime = moment().format('DD/MM/YYYY H:mm:ss');

                    // Grid
                    doc.autoTable(columns, rows, {
                        theme: 'grid',
                        margin: { top: 60 },
                        styles: { halign: 'left', fontSize: 6, cellPadding: { right: 3, left: 3 } },
                        headStyles: {
                            fillColor: [13, 50, 92],
                            textColor: [255, 255, 255],
                            fontSize: 8,
                        },
                        bodyStyles: {
                            cellPadding: 3
                        },
                        didDrawPage() {
                            doc.addImage(imgData, 'PNG', 35, 5, 70.5, 50, 'FAST');
                            doc.setFontSize(8);
                            doc.text(`Data: ${dateTime}`, doc.internal.pageSize.width - 165, 30);
                            doc.text(`Usuário: ${self.authUser.username}`, doc.internal.pageSize.width - 165, 45);
                            doc.setFontSize(11);
                            // tslint:disable-next-line: max-line-length
                            const textWidth = doc.getStringUnitWidth(title.toUpperCase()) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                            const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
                            doc.text(textOffset, 30, title.toUpperCase());
                        }
                    });
                    if (print) {
                        const out = doc.output();
                        const url = btoa((out));
                        self.observ.next(url);
                    } else {
                        doc.save(`${fileName}.pdf`);
                    }
                };
            });
    }

    b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
}

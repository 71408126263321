<ng-container *ngIf="formGroup.get(formcontrolname).invalid">
     <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('passwordsNotMatching')">
        {{ 'ERROR.PASSWORD_NOT_MATCHING' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('email')">
        {{ 'ERROR.EMAIL' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('maxlength')">
        {{ 'ERROR.MAX_LENGTH' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('minlength')">
        {{ 'ERROR.MIN_LENGTH' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('max')">
        {{ 'ERROR.MAX' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('min')">
        {{ 'ERROR.MIN' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('pattern')">
        {{ 'ERROR.PATTERN' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('invalid')">
        {{ 'ERROR.INVALID' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('cnpj')">
        {{ 'ERROR.CNPJ' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('cpf')">
        {{ 'ERROR.CPF' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('blank')">
        {{ 'ERROR.BLANK' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('whitespaces')">
        {{ 'ERROR.WHITESPACES' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('atLeastOne')">
        {{ 'ERROR.AT_LEAST_ONE' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('requiredFileType')">
        {{ 'ERROR.REQUIRED_FILE_TYPE' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('nonzero')">
        {{ 'ERROR.NONZERO' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('document')">
        {{ 'ERROR.DOCUMENT' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('cnh')">
        {{ 'ERROR.CNH' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('birthDate')">
        {{ 'ERROR.BIRTH_DATE' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('dateValidity')">
        {{ 'ERROR.DATE_VALIDITY' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('dateLimit')">
        {{ 'ERROR.DATE_LIMIT' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('matDatepickerParse')">
        {{ 'ERROR.MAT_DATEPICKER_PARSE' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('zipCode')">
        {{ 'ERROR.ZIP_CODE' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('Maskerror')">
        {{ 'ERROR.MASK_ERROR' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('required')">
        {{ 'ERROR.REQUIRED' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
    <span *ngIf="control.hasError('maxSize')">
        {{ 'ERROR.MAX_SIZE' | translate }}
    </span>
    <!-- ERROR TRADUZIDO -->
</ng-container>
import { Injectable } from '@angular/core';

import swal, { SweetAlertResult } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(
    private translate: TranslateService
  ) { }

  success(title: string , underText: string = ''): Promise<SweetAlertResult> {
    return swal.fire({
      title,
      text: underText,
      icon: 'success',
      confirmButtonColor: 'green',
      heightAuto: false
    });
  }

  warning(title: string , underText: string = ''): Promise<SweetAlertResult> {
    return swal.fire({
      title,
      text: underText,
      icon: 'info',
      confirmButtonColor: 'orange',
      heightAuto: false
    });
  }

  error(title: string , underText: string = ''): Promise<SweetAlertResult> {
    return swal.fire({
      title,
      text: underText,
      icon: 'error',
      confirmButtonColor: '#5bb8c2',
      heightAuto: true,
      customClass: {
        popup: 'popup-class-general',
        header: 'header-class-general',
        title: 'title-class-error',
        content: 'content-class-general',
      }
    });
  }

  confirm(title: string , text: string = ''): Promise<SweetAlertResult> {
    let yes = 'Sim';
    let no = 'Não';

    this.translate.get(['COMMOM.YES', 'COMMON.NO']).subscribe(result => {
      yes = result['COMMOM.YES'];
      no = result['COMMON.NO'];
    });

    return swal.fire({
      title,
      text,
      icon: 'warning',
      reverseButtons: false,
      showCancelButton: true,
      confirmButtonColor: 'text-primary',
      cancelButtonColor: 'text-secundary',
      confirmButtonText: yes,
      cancelButtonText: no,
      heightAuto: true
    });
  }
}

export class PageConfig {

    data(rand: boolean = false){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                id_especialidade: [1],
                descricao: 'Aplicação de flúor',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 2,
                id_especialidade: [1],
                descricao: 'Aplicação de selante',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 3,
                id_especialidade: [1],
                descricao: 'Cirurgia simples',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 4,
                id_especialidade: [1],
                descricao: 'Restauração',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 5,
                id_especialidade: [1],
                descricao: 'Tratamento de bruxismo',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 6,
                id_especialidade: [1],
                descricao: 'Tratamento de canal',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
        ];

        return data;

    }
    
}
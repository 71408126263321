import { Injectable } from '@angular/core';
import { ParametersService } from './parameters.service';
import { StyleService, Style } from '@vex/services/style.service';
import { ConfigService } from '@vex/services/config.service';
import { ColorVariable } from '@vex/components/config-panel/color-variables';
import { ConfigName } from '@vex/interfaces/config-name.model';

@Injectable({
  providedIn: 'root'
})
export class LoaderThemeService {

  constructor(
    private parametersService: ParametersService,
    private styleService: StyleService,
    private configService: ConfigService
  ) { }

  color: any;
  config: any;
  style: Style;
  layout: ConfigName;

  boot() {

    this.parametersService.loadOneWithKey('THEME_LAYOUT').subscribe(res => {
      const layout: ConfigName = res.value as ConfigName;
      if (layout) {
        this.configService.setConfig(layout);
        this.layout = layout;
      }
    });

    this.parametersService.loadOneWithKey('THEME_COLOR').subscribe(res => {
      const color: ColorVariable = res.value ? JSON.parse(res.value) : null;
      if (color) {
        this.styleService.setColor(color);
        this.color = color;
      }
    });

    this.parametersService.loadOneWithKey('THEME_STYLE').subscribe(res => {
      const style: Style = res.value as Style;
      if (style) {
        this.styleService.setStyle(style);
        this.style = style;
      }
    });

    this.parametersService.loadOneWithKey('THEME_CONFIG').subscribe(res => {
      const config = res.value ? JSON.parse(res.value) : {};
      this.configService.updateConfig(config);
      this.config = config;
    });
  }
}

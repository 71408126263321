import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { NotAuthRoutingModule } from './not-auth-routing.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SharedModule,
        FlexLayoutModule,
        NotAuthRoutingModule
    ]
})
export class NotAuthModule {
}

export class PageConfig {

    data(rand: boolean = false){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                descricao: 'Boleto Bancário',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 2,
                descricao: 'Cartão de Crédito',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 3,
                descricao: 'Cartão de Débito',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 4,
                descricao: 'Cheque',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 5,
                descricao: 'Dinheiro',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 6,
                descricao: 'Transferência Bancária',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
        ];

        return data;

    }
    
}
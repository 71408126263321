<div class="w-full h-full bg-wallpaper" fxLayout="column" fxLayoutAlign="center center">
  
  <div @fadeInUp class="card overflow-hidden w-full max-w-xs" style="position: relative;">
    <div class="p-6 pb-0" fxLayout="column" fxLayoutAlign="center center">
      <div class="fill-current text-center mt-4">
        <img class="w-30" src="assets/images/logos/logo.svg">
      </div>
    </div>

    <div class="text-center mt-8">
      <h2 class="title m-0">{{ 'NOTAUTH.LOGIN.TITLE'  | translate }}</h2>
      <h4 class="body-2 text-secondary m-0">{{ 'NOTAUTH.LOGIN.SUBTITLE' | translate }}</h4>
    </div>

    <div [formGroup]="form" class="p-6" fxLayout="column" fxLayoutGap="16px">
      <div fxFlex="auto" fxLayout="column">
        <mat-form-field fxFlex="grow">
          <mat-label>{{ 'LABEL.USER' | translate }}</mat-label>
          <input formControlName="email" matInput required>
          <mat-error *ngIf="form.get('email').hasError('required')">
            {{ 'NOTAUTH.LOGIN.ERROR.USER' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>{{ 'LABEL.PASSWORD' | translate }}</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button #passwordInput mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button" (click)="toggleVisibility()">
            <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
            <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
          </button>
          <mat-error *ngIf="form.get('password').hasError('required')">
            {{ 'NOTAUTH.LOGIN.ERROR.PASSWORD' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox class="caption" color="primary" [disabled]="form.invalid || globalService.onProcessForm">{{ 'LABEL.REMEMBER_ME' | translate }}</mat-checkbox>
        <a [routerLink]="['/not-auth/forgot-password']"
          class="caption">{{ 'NOTAUTH.LOGIN.LINK.FORGOT_PASSWORD' | translate }}</a>
      </div>

      <!-- <button (click)="onLogin()" [disabled]="form.invalid"
      [ngStyle]="{'backgroundColor' : colorVariables.blue.default, 'color': 'white'}" mat-raised-button type="button">
        {{ 'NOTAUTH.LOGIN.BUTTON.SIGN_IN' | translate | uppercase }}
      </button> -->

      <button (click)="onLogin()" [disabled]="form.invalid || globalService.onProcessForm" color="primary" mat-raised-button type="button">
        {{ 'NOTAUTH.LOGIN.BUTTON.SIGN_IN' | translate | uppercase }}
      </button>
    </div>

    <mat-progress-bar mode="indeterminate" class="progress-bar-bottom"
    *ngIf="globalService.onProcessForm"></mat-progress-bar>
  </div>

</div>
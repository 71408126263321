<div class="card mt-6">
    
    <div class="px-gutter py-4 border-b">
      <h2 class="title m-0">Sobre mim</h2>
    </div>

    <div class="px-gutter py-4"
        gdColumns="1fr"
        gdGap="24px">
        <div>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque eos repudiandae expedita inventore, eum voluptate culpa dolor ducimus facilis, cupiditate tempore impedit labore quis optio ullam laboriosam cum provident? Eos.</p>
        </div>
    </div>

    <div class="px-gutter py-4"
        gdColumns="1fr 1fr 1fr"
        gdColumns.lt-lg="1fr 1fr 1fr"
        gdColumns.xs="1fr"
        gdGap="24px">

        <div>
            <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of telefones">
                <div class="w-10 h-10 text-warn ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icDelete" size="20px"></ic-icon>
                </div>
                
                <div class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <ic-icon [icon]="icPhone" size="20px"></ic-icon>
                </div>
      
                <div>
                  <p class="m-0 body-1">{{item.telefone}}</p>
                  <p class="m-0 caption text-hint">{{item.tipo}}</p>
                </div>
                
            </div>

            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                <div class="w-10 h-10 rounded-full bg-gray-light text-dark ltr:mr-3 rtl:ml-3 cursor-pointer flex items-center justify-center">
                  <ic-icon [icon]="icAdd" size="20px"></ic-icon>
                </div>
      
                <p class="m-0 body-1 cursor-pointer">Adicionar telefone</p>
            </div>
        </div>

        <div>
            <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of emails">
                <div class="w-10 h-10 text-warn ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icDelete" size="20px"></ic-icon>
                </div>

                <div class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <ic-icon [icon]="icMail" size="20px"></ic-icon>
                </div>
      
                <div>
                    <p class="m-0 body-1">{{item.email}}</p>
                    <p class="m-0 caption text-hint">{{item.tipo}}</p>
                  </div>
            </div>

            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                <div class="w-10 h-10 rounded-full bg-gray-light text-dark ltr:mr-3 rtl:ml-3 cursor-pointer flex items-center justify-center">
                  <ic-icon [icon]="icAdd" size="20px"></ic-icon>
                </div>
      
                <p class="m-0 body-1 cursor-pointer">Adicionar e-mail</p>
            </div>
        </div>

        <div>
            <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of sociais">
                <div class="w-10 h-10 text-warn ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icDelete" size="20px"></ic-icon>
                </div>

                <div class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <ic-icon [icon]="icWhatshot" size="20px"></ic-icon>
                </div>
      
                <div>
                    <p class="m-0 body-1">{{item.rede}}</p>
                    <p class="m-0 caption text-hint">{{item.tipo}}</p>
                  </div>
            </div>

            <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                <div class="w-10 h-10 rounded-full bg-gray-light text-dark ltr:mr-3 rtl:ml-3 cursor-pointer flex items-center justify-center">
                  <ic-icon [icon]="icAdd" size="20px"></ic-icon>
                </div>
      
                <p class="m-0 body-1 cursor-pointer">Adicionar rede social</p>
            </div>
        </div>

    </div>
</div>

<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="25px" class="card mt-6">

    <div class="card" fxFlex="50">

        <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">Formação</h2>
        </div>

        <br><br><br>
        <vex-data-table
            [title]="'Formacao'"
            [selection]="selectionFormacao"
            [data]="dataSourceFormacao"
            [columns]="columnsFormacao"
            [config]="configurationFormacao"
            [createShow]="true"

            (refresh)="onRefresh($event)"
            (search)="onSearch($event)"
        ></vex-data-table>

    </div>

    <div class="card" fxFlex="50">

        <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">Experiências Profissionais</h2>
        </div>

        <br><br><br>
        <vex-data-table
            [title]="'Experiencias'"
            [selection]="selectionExperiencias"
            [data]="dataSourceExperiencias"
            [columns]="columnsExperiencias"
            [config]="configurationExperiencias"
            [createShow]="true"

            (refresh)="onRefresh($event)"
            (search)="onSearch($event)"
        ></vex-data-table>

    </div>

</div>

<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="25px" class="card mt-6">

    <div class="card" fxFlex="50">

        <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">Certificados</h2>
        </div>

        <br><br><br>
        <vex-data-table
            [title]="'Certificados'"
            [selection]="selectionCertificados"
            [data]="dataSourceCertificados"
            [columns]="columnsCertificados"
            [config]="configurationCertificados"
            [createShow]="true"

            (refresh)="onRefresh($event)"
            (search)="onSearch($event)"
        ></vex-data-table>

    </div>

    <div class="card" fxFlex="50">

        <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">Idiomas</h2>
        </div>

        <br><br><br>
        <vex-data-table
            [title]="'Idiomas'"
            [selection]="selectionIdiomas"
            [data]="dataSourceIdiomas"
            [columns]="columnsIdiomas"
            [config]="configurationIdiomas"
            [createShow]="true"

            (refresh)="onRefresh($event)"
            (search)="onSearch($event)"
        ></vex-data-table>

    </div>

</div>
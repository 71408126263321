import { Injectable, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { SwalService } from './swal.service';
import { UploadValidator } from '../validators/uploadValidator';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private _swalService: SwalService
  ) { }

  paginatorWasChanged = new EventEmitter<any>();

  // tslint:disable-next-line: only-arrow-functions
  toFormData = (function () {

    const Type = {
      isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object ' + 'Array' + ']';
      },
      isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object ' + 'Object' + ']';
      },
    };

    return function format(params) {
      const formData = new FormData();
      const store = {};
      for (const key in params) {
        if (Type.isObject(params[key]) || Type.isArray(params[key])) {
          for (const innerKey in params[key]) {
            if (params[key].hasOwnProperty(innerKey)) {
              store[key + '[' + innerKey + ']'] = params[key][innerKey];
            }
          }
        } else {
          store[key] = params[key];
        }
      }

      function check() {
        for (const key in store) {
          if (store.hasOwnProperty(key)) {
            if (Type.isObject(store[key]) || Type.isArray(store[key])) {
              return format(store);
            }
          }
        }
        for (const key of Object.keys(store)) {
          const value = store[key];
          if (value) { formData.append(key, value); }
        }
        return formData;
      }

      return check();
    };
  }());

  formIsValid(form: FormGroup): boolean {
    if (form.valid) {
      return true;
    } else {
      this.markFormTouched(form);
      this._swalService.error('Ocorreu um erro!', 'Existem campos que não foram preenchidos adequadamente!');
      return false;
    }
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl && abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  setValuesForm(form: FormGroup, item: any, exceptions = []): void {
    const keys = Object.keys(form.getRawValue());
    for (let i = 0; i < keys.length; i++) {
      if (!(exceptions.includes(keys[i]))) {
        if (form.get(keys[i])) {
          form.get(keys[i]).setValue(item[keys[i]]);
        }
      }
    }
  }

  enableForm(form: FormGroup, exceptions: Array<string>): void {
    const keys = Object.keys(form.getRawValue());

    for (let i = 0; i < keys.length; i++) {
      if (!(exceptions.includes(keys[i]))) {
        form.get(keys[i]).enable();
      }
    }
  }

  disableForm(form: FormGroup, exceptions: Array<string>): void {
    const keys = Object.keys(form.getRawValue());

    for (let i = 0; i < keys.length; i++) {
      if (!(exceptions.includes(keys[i]))) {
        form.get(keys[i]).disable();
      }
    }
  }

  markFormTouched(form: FormGroup): void {
    const keys = Object.keys(form.getRawValue());

    for (const key of keys) {
      form.get(key).markAsTouched();
    }
  }

  upload(file, callback) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
      if (reader.result instanceof ArrayBuffer) {
        const arrFile = (new Uint8Array(reader.result)).subarray(0, 4);
        let header = '';

        arrFile.forEach(item => {
          header += item.toString(16);
        });

        if (!UploadValidator(header)) {
          return callback({ error: 'Apenas imagens são suportadas para cadastro.' });
        }
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          return callback({ result: reader.result });
        };
      } else {
        return callback({ error: 'Falha ao verificar o arquivo. Por favor, tente novamente.' });
      }
    };
  }

  validateFilePdf(file): boolean {
    const mimeType = file.type;
    if (mimeType.match(/application\/pdf/)) {
      return true;
    } else {
      return false;
    }
  }

  validateFileZip(file): boolean {
    const mimeType = file.type;
    if (mimeType.match(/application\/zip/) || mimeType.match(/application\/x-zip-compressed/)) {
      return true;
    } else {
      return false;
    }
  }

  momentToDate(date): string {
    if (typeof date === 'object') {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return date;
    }
  }

  dateFormat(date: string | Date) {
    return moment(date).format('YYYY/MM/DD');
  }

  dateTimeFormat(date: string | Date) {
    return moment(date).format('YYYY/MM/DD HH:mm:ss');
  }

  checkDateIsBefore(date): boolean {
    return moment(date).isBefore(moment());
  }

  formatDecimalValues(value, decimalPlaces): number {
    if (value && decimalPlaces) {
      const wholePlaces = value.length - decimalPlaces;
      value = value.slice(0, wholePlaces) + '.' + value.slice(wholePlaces);

      return parseFloat(value);
    }
    return null;
  }

  // FORM DATA ANTIGO
  // toFormData(formValue) {
  //   const formData = new FormData();

  //   for (const key of Object.keys(formValue)) {
  //     const value = formValue[key];

  //     if (value !== null && value !== undefined) {
  //       if (Array.isArray(value)) {
  //         for (var i = 0; i < value.length; i++) {
  //           formData.append(`${key}[]`, value[i]);
  //         }
  //       } else {
  //         formData.append(key, value);
  //       }
  //     }
  //   }
  //   return formData;
  // }


  children(element): void {
    element.children.map(item => {
      if (item.children) {
        delete item.url;
        this.children(item);
      }
      return item;
    });
  }

  bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) { return '0 Byte'; }
    const parse = Math.floor(Math.log(bytes) / Math.log(1024));
    // tslint:disable-next-line: radix
    const i = parseInt(parse.toString());
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  snakeCase(string: string) {
    return string.replace(/\d+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }



  generateHexColor(string: any) {
    function hashCode(str: string) { // java String#hashCode
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    function intToRGB(i) {

      // tslint:disable-next-line: no-bitwise
      const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

      return '00000'.substring(0, 6 - c.length) + c;
    }

    return '#' + intToRGB(hashCode(string));
  }




}

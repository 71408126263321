export class PageConfig {

    data(){

        let data = [];
        let qtd = Math.floor(Math.random() * 20);

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    nome: 'Consultório ' + (i+1),
                    endereco: 'Rua 1 Qd 3 Lt 3',
                    expediente: 'Seg a Sex - 08h as 18h',
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        }
        return data;

    }
    
}
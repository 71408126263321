import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ServiceInterface } from '../interfaces/service.interface';
import { AclAction } from '../models/acl-action.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AclService implements ServiceInterface {
  API_BASE_URL: string = environment.API;

  constructor(private _httpService: HttpService) { }

  loadAll(payload?: any): Observable<AclAction[]> {
    const url = `${this.API_BASE_URL}/actions`;
    return this._httpService.get(url, payload).pipe(map((result: any) => result));
  }

  loadOne(id: number): Observable<AclAction> {
    const url = `${this.API_BASE_URL}/actions/${id}`;
    return this._httpService.get(url).pipe(map((result: any) => result));
  }

  create(payload: any): Observable<AclAction> {
    const url = `${this.API_BASE_URL}/actions`;
    return this._httpService.post(url, payload).pipe(map((result: any) => result));
  }

  update(id: number, payload: any): Observable<AclAction> {
    const url = `${this.API_BASE_URL}/actions/${id}`;
    return this._httpService.put(url, payload).pipe(map((result: any) => result));
  }

  toggleStatus(id: number, payload: any): Observable<AclAction> {
    const url = `${this.API_BASE_URL}/actions/${id}/toggle-status`;
    return this._httpService.put(url, payload).pipe(map((result: any) => result));
  }

  destroy(id: number): Observable<AclAction> {
    const url = `${this.API_BASE_URL}/actions/${id}`;
    return this._httpService.delete(url).pipe(map((result: any) => result));
  }

  getUserMenus(payload?: any): Observable<any> {
    const url = `${this.API_BASE_URL}/acl/user-menus`;
    return this._httpService.get(url, payload).pipe(map((result: any) => result));
  }

  private keysFunctions(ref) {
    return {
      add: () => ref.onCreate(),
      edit: (element: any) => ref.onUpdate(element),
      view: (element: any) => ref.onView(element),
      delete: (element: any) => ref.onDelete(element),
      deleteAll: (element: any) => ref.onDeleteAll(element),
      toggleStatus: (element: any) => ref.toggleStatus(element),
    };
  }

  getAclActions(ref: any, actions: AclAction[]) {
    const keysFunctions = this.keysFunctions(ref);
    const arrayActions = [];
    for (const action of actions) {
      if (Object.getOwnPropertyNames(keysFunctions).includes(action.key)) {
        arrayActions.push({ icon: action.icon, title: action.name, function: keysFunctions[action.key] });
      }
    }
    return arrayActions;
  }

  getPermissionButtons(actions: AclAction[], keysActions = ['add']) {
    const arrayActions = {};
    for (const action of actions) {
      arrayActions[action.key] = keysActions.includes(action.key);
    }
    return arrayActions;
  }
}

<ng-container [formGroup]="formGroup">
    <img [src]="image ? image : defaultImage"
        [ngClass]="{ 'thumb-img avatar': true, 'disabled': isDisabled() }"
        (click)="clickInput()">

    <input #inputField type="file" [id]="formcontrolname" [disabled]="isDisabled()" hidden="true"
        [required]="checkRequired()" accept="image/jpg, image/jpeg, image/png, image/bmp" autocomplete="off"
        style="display: none;">
    <mat-error>
        <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
    </mat-error>
</ng-container>
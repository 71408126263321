<vex-page-layout>

  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div class="px-gutter bg-primary w-full h-full flex flex-col flex flex-col sm:flex-row justify-between">
      <div class="pt-6 text-primary-contrast">
        <h1 class="title mt-0 mb-1 text-primary-contrast">{{title}}</h1>
        <vex-breadcrumbs [crumbs]="[title]"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>

  <vex-page-layout-content class="-mt-16 px-gutter">

    <vex-data-table
      [title]="title"
      [selection]="selection"
      [data]="dataSource"
      [columns]="columns"
      [config]="configuration"
      [createShow]="false"

      (refresh)="onRefresh($event)"
      (search)="onSearch($event)"
    ></vex-data-table>

  </vex-page-layout-content>

</vex-page-layout>
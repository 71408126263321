<vex-page-layout>

    <!--HEADER-->
    <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
      <div class="px-gutter bg-primary w-full h-full flex flex-col flex flex-col sm:flex-row justify-between">
        <div class="pt-6 text-primary-contrast">
          <h1 class="title mt-0 mb-1 text-primary-contrast">{{title}}</h1>
          <vex-breadcrumbs [crumbs]="[title]"></vex-breadcrumbs>
        </div>
      </div>
    </vex-page-layout-header>

    <!--CONTENT-->  
    <vex-page-layout-content class="-mt-16 px-gutter">
        
        <!--BUTTONS-->
        <div class="card overflow-auto -mt-16">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
                <h4 class="my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
                    <span><strong>{{ action }}</strong></span>
                    <span *ngIf="operation === 'EDIT' || operation === 'VIEW'"><strong> #{{ id }}</strong></span>
                </h4>

                <span fxFlex></span>

                <button mat-icon-button color="primary" [matTooltip]="'Salvar'" (click)="onSave()"
                *ngIf="operation === 'NEW' || operation === 'EDIT'"
                [disabled]="formStepper1.invalid">
                    <mat-icon [icIcon]="icSave"></mat-icon>
                </button>

                <button mat-icon-button color="primary" [matTooltip]="'Editar'" (click)="onEdit()"
                *ngIf="operation === 'VIEW'">
                    <mat-icon [icIcon]="icEdit"></mat-icon>
                </button>

                <button mat-icon-button color="primary" [matTooltip]="'Excluir'" (click)="onDelete()"
                *ngIf="operation === 'EDIT' || operation === 'VIEW'">
                    <mat-icon [icIcon]="icDelete"></mat-icon>
                </button>

                <button mat-icon-button color="primary" [matTooltip]="'Fechar'" (click)="onClose()">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                </button>

            </div>
        </div>

        <!--FORM-->
        <div class="card overflow-hidden">
            <mat-horizontal-stepper #stepper="matHorizontalStepper">
                <ng-template matStepperIcon="edit">
                    <mat-icon [icIcon]="icDone"></mat-icon>
                </ng-template>
        
                <ng-template matStepperIcon="done">
                    <mat-icon [icIcon]="icDone"></mat-icon>
                </ng-template>
        
                <mat-step [stepControl]="formStepper1">
                    <form [formGroup]="formStepper1">
                        <ng-template matStepLabel>Dados cadastrais</ng-template>
        
                        <div class="mt-4" fxLayout="row" fxLayoutGap="8px">

                            <mat-form-field fxFlex="15">
                                <mat-label>Id</mat-label>
                                <input formControlName="id" matInput required>
                            </mat-form-field>

                            <mat-form-field fxFlex="auto">
                                <mat-label>Descrição</mat-label>
                                <input formControlName="descricao" matInput required>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutGap="8px">

                            <mat-form-field fxFlex="auto">
                              <mat-label>Especialidade</mat-label>      
                              <mat-select formControlName="id_especialidade" multiple [(ngModel)]="idSelecteds">      
                                  <mat-option *ngFor="let item of arrEspecialidades" [value]="item.id">
                                      <span>{{ item.descricao }}</span>
                                  </mat-option>
                              </mat-select>      
                            </mat-form-field>
                    
                        </div>

                        <div class="mt-4" fxLayout="row" fxLayoutGap="8px">

                            <div fxFlex="15" fxLayout="column">
                                <mat-label>Status</mat-label>
                                <mat-slide-toggle formControlName="status" color="primary"></mat-slide-toggle>
                            </div>

                        </div>
                        
                    </form>

                </mat-step>

            </mat-horizontal-stepper>
        </div>

    </vex-page-layout-content>  
</vex-page-layout>
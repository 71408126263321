import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Auth } from 'app/shared/models/auth.model';
import { ClearAuthData } from 'app/store/authentication/actions';
import { ClearNotificationData } from 'app/store/notification/actions';
import { Subscription } from 'rxjs';
import { SocketService } from 'app/shared/services/socketio.service';
import { NotificationCount } from 'app/shared/models/notiifcation-count.model';

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(
        private _router: Router,
        private _store: Store<{ auth: Auth }>,
        private _storeNotificationCount: Store<{ notification: NotificationCount}>,
        private _socketService: SocketService,
    ) { }

    ngOnInit(): void {
        this._store.dispatch(ClearAuthData());
        sessionStorage.clear();
        // this._storeNotificationCount.dispatch(ClearNotificationData());
        // this._socketService.socket.close();
        // this._socketService.socket.disconnect();
        // this._socketService.socket.close();
        // this._socketService.socket.removeAllListeners();
        // this._socketService.socketUser.disconnect();
        // this._socketService.socketUser.close();
        // this._socketService.socketUser.removeAllListeners();
        this._router.navigate(['/not-auth']);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

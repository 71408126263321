<div class="card mt-6">
    
    <div class="px-gutter py-4 border-b" style="position: relative;">
      <h2 class="title m-0">Fotos</h2>

      <div class="py-3" fxLayout="row" fxLayoutAlign="start center" class="mr-6"
        style="position: absolute; right: 0; margin-top: -35px;">
          <div class="w-10 h-10 rounded-full bg-gray-light text-dark ltr:mr-3 rtl:ml-3 cursor-pointer flex items-center justify-center">
            <ic-icon [icon]="icAdd" size="20px"></ic-icon>
          </div>

          <p class="m-0 body-1 cursor-pointer">Adicionar</p>
      </div>
    </div>

    <div class="px-gutter py-4"
         gdColumns="1fr 1fr 1fr 1fr 1fr"
         gdColumns.lt-lg="1fr 1fr 1fr"
         gdColumns.xs="1fr"
         gdGap="24px">
      <img *ngFor="let i of dataSource"
           class="rounded w-full"
           gdGridAlign="center"
           src="assets/images/avatars/photo.jpg"/>
    </div>
</div>
<mat-form-field [formGroup]="formGroup" fxFlex="100">
  <mat-label>{{ label }}</mat-label>
  <input matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" [disabled]="disabled"
         [formControlName]="formcontrolname" [required]="checkRequired()"
         [placeholder]="placeholder"
         (blur)="onBlur()" (ngModelChange)="onChange($event)"
         #input>
  <mat-icon *ngIf="iconName" matSuffix class="secondary-text">{{ iconName }}</mat-icon>
  <mat-error>
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>
</mat-form-field>

import { TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../functions/httpLoaderFactory';
import { HttpClient } from '@angular/common/http';

export const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}
export class PageConfig {

    dataUsuarios(){

        let data = [];
        let qtd = Math.floor(Math.random() * 20);

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    avatar: 'assets/images/avatars/photo.jpg',
                    nome: 'Leandro Melo',
                    usuario: '@leandroO5',
                    perfil: 'Grupo ' +i+1,
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        }
        return data;

    }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { PipesModule } from './pipes/pipes.module';
import { ExportDataService } from './services/export-data.service';
import { TranslateModule } from '@ngx-translate/core';
import { SeveralComponentsModule } from './components/several-components/several-components.module';
import { FormComponentsModule } from './components/form-components/form-components.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PipesModule,
    SeveralComponentsModule,
    FormComponentsModule
  ],
  providers: [
    ExportDataService,
  ],
  exports: [
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    SeveralComponentsModule,
    FormComponentsModule
  ]
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { ViewInterface } from 'app/shared/interfaces/view.interface';
import { Operation } from 'app/shared/enums/operation';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { Config } from 'app/shared/components/several-components/data-table/config';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from '../form/form.component';

import icView from '@iconify/icons-ic/twotone-visibility';
import icUpdate from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete-forever';
import icSearch from '@iconify/icons-ic/twotone-search';

import { TranslateService } from '@ngx-translate/core';

import { GlobalService } from 'app/shared/services/global.service';
import { Router } from '@angular/router';
import { ToastService } from 'app/shared/services/toast.service';
import { SwalService } from 'app/shared/services/swal.service';

import * as PageConfigServicos from 'app/mockups/page-config-servicos';
import { ModalProceduresComponent } from '../modal-procedimentos/modal';

@Component({
  selector: 'vex-app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
  constructor(
    private _translate: TranslateService,
    private _router: Router,
    public globalService: GlobalService,
    private _toastService: ToastService,
    private _swalService: SwalService,
    private dialog: MatDialog
  ) { }

  title = 'Procedimentos';
  icSearch = icSearch;

  icView = icView;
  icUpdate = icUpdate;
  icDelete = icDelete;

  operation: Operation;

  //TABLE
  paginationInitial = { page: 1, limit: 10 };
  options = {};

  selection = new SelectionModel<any>(true, []);
  configuration = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  dataSource: any = [];
  dataSourceBase: any = [];

  columns: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },

    { label: 'Descrição', property: 'descricao', type: 'text', visible: true },
    { label: null, labelFilter: 'Ações', property: 'btn_del', type: 'button', visible: true, tooltip: 'Excluir', cssClasses: ['text-primary', 'text-right', 'w-1'] },
  ];

  ngOnInit(): void {
    this.onRefresh();
  }

  onRefresh(event?): void {
    this.dataSource = [];
    this.dataSource = new PageConfigServicos.PageConfig().data();
    this.dataSourceBase = this.dataSource;
    this.configuration.total = this.dataSource.length;
  }

  onCreate(): void {
    //this._router.navigate([`auth/procedures/new`]);

    this.dialog.open(ModalProceduresComponent, { panelClass: 'dialog-default' })
    .afterClosed().subscribe((data) => {
      if (data) {
        this.onRefresh();
      }
    });

  }

  onClickRow(row): void {
    this._router.navigate([`auth/procedures/${row.id}`]);
  }

  onSearch(search: string) {
    this.dataSource = this.globalService.searchLocal(search, this.dataSourceBase)
  }

  onStarred(row){
    if(row.favorito.icon === 'star'){
      row.favorito.icon = 'star_outline';
      row.isFavorito = false;
    
    }else{
      row.favorito.icon = 'star';
      row.isFavorito = true;
    }
  }

  getStarred(event){
    if(event === 'star'){
      this.dataSource = this.dataSourceBase.filter(item => item.isFavorito === true);
      this._toastService.info('Exibindo apenas favoritos');
    
    }else{
      this.dataSource = this.dataSourceBase;
      this._toastService.info('Exibindo todos');
    }
  }

  onDelete(row) {
    this._swalService.confirm(`Excluir registro #${row.id}`, 'Tem certeza que deseja excluir este registro?')
    .then((res) => {
      if(res.value){
        this._toastService.success('O registro foi excluído!');
      }
    })
  }

  getAction(event){
    switch (event.action) {
      case 'delete': 
        this.onDelete(event.row);
        break;

      case 'starred': 
        this.onStarred(event.row);
        break;

      default:
        true;
    }
  }

  onToggleStatus(row): void { }
}

<div *ngIf="config$ | async as setting" class="config-panel">
  <h2 class="headline mb-4">
    <ic-icon [icon]="icSettings" class="mr-3" inline="true"></ic-icon>
    <span>{{ 'CONFIG.CONFIGURATION' | translate | titlecase }}</span>
  </h2>

  <div class="section">
    <h5 class="subheading">{{ 'CONFIG.THEME_BASE' | translate | uppercase }}</h5>

    <div (click)="selectedStyle === Style.dark ? disableDarkMode() : enableDarkMode()"
      *ngIf="selectedStyle$ | async as selectedStyle"
      class="rounded-full mt-2 flex items-center cursor-pointer relative bg-contrast-black text-contrast-white"
      matRipple>
      <div class="m-2 h-6 w-6 rounded-full">
        <ic-icon *ngIf="selectedStyle === Style.dark" [icon]="icCheck" size="24px"></ic-icon>
        <ic-icon *ngIf="selectedStyle !== Style.dark" [icon]="icClose" size="24px"></ic-icon>
      </div>
      <p class="ml-1 font-medium text-sm">{{ 'CONFIG.DARK_MODE' | translate | uppercase }} </p>
    </div>
  </div>

  <div class="section">
    <h5 class="subheading">{{'CONFIG.THEME_COLORS' | translate | uppercase }}</h5>

    <div (click)="selectColor(color.value)" *ngFor="let color of colorVariables | keyvalue"
      [class.selected]="isSelectedColor(color.value)" [style.backgroundColor]="color.value.light"
      [style.color]="color.value.default"
      class="vex-color-picker rounded-full mt-2 flex items-center cursor-pointer relative" matRipple>
      <div [style.backgroundColor]="color.value.default" class="m-2 h-6 w-6 rounded-full">
        <ic-icon *ngIf="isSelectedColor(color.value)" [icon]="icCheck" size="24px"></ic-icon>
      </div>
      <p class="ml-1 font-medium text-sm">{{ ('COLOR.' + color.key | uppercase) | translate | uppercase }}</p>
    </div>
  </div>

  <div class="section">
    <h5 class="subheading">{{'CONFIG.THEME_STYLE' | translate }}</h5>

    <div *ngFor="let config of configs; let first = first" [class.mt-6]="!first" class="rounded">
      <div class="layout-image rounded overflow-hidden relative hover:bg-hover shadow-8">
        <img [src]="config.imgSrc" class="w-full block">
        <div class="layout-image-overlay" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
            <button (click)="setConfig(config.id, Style.light)"
                  class="bg-contrast-white text-contrast-black"
                  fxFlex="none"
                  mat-raised-button
                  type="button">{{'CONFIG.LIGHT' | translate }}
          </button>
          <button (click)="setConfig(config.id, Style.default)"
                  color="primary"
                  fxFlex="none"
                  mat-raised-button
                  type="button">{{ 'CONFIG.DEFAULT' | translate }}
          </button>
          <button (click)="setConfig(config.id, Style.dark)"
                  class="bg-contrast-black text-contrast-white"
                  fxFlex="none"
                  mat-raised-button
                  type="button">{{ 'CONFIG.DARK' | translate }}
          </button>
        </div>
      </div>
      <div class="text-center body-2 mt-2">{{ config.name }}</div>
    </div>
  </div>

  <div class="section">
    <h5 class="subheading">{{ 'CONFIG.TOOLBAR' | translate  | uppercase }}</h5>

    <div class="section-content">
      <h5 class="subheading">{{ 'CONFIG.POSITION' | translate | uppercase }}</h5>

      <mat-radio-group (change)="toolbarPositionChange($event)" [value]="setting.toolbar.fixed ? 'fixed' : 'static'"
        fxLayout="column" fxLayoutGap="12px">
        <mat-radio-button value="fixed">{{ 'CONFIG.FIXED' | translate | titlecase }}</mat-radio-button>
        <mat-radio-button value="static">{{ 'CONFIG.STATIC' | translate | titlecase }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="section">
    <h5 class="subheading">{{'CONFIG.FOOTER' | translate | uppercase }}</h5>

    <div class="section-content">
      <mat-slide-toggle (change)="footerVisibleChange($event)" [checked]="setting.footer.visible">
        {{'CONFIG.VISIBLE' | translate | titlecase }}
      </mat-slide-toggle>

      <h5 class="subheading">{{'CONFIG.POSITION' | translate | uppercase }}</h5>

      <mat-radio-group (change)="footerPositionChange($event)" [value]="setting.footer.fixed ? 'fixed' : 'static'"
        fxLayout="column" fxLayoutGap="12px">
        <mat-radio-button value="fixed">{{ 'CONFIG.FIXED' | translate | titlecase }}</mat-radio-button>
        <mat-radio-button value="static">{{ 'CONFIG.STATIC' | translate | titlecase }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<vex-page-layout>

  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div class="px-gutter bg-primary w-full h-full flex flex-col flex flex-col sm:flex-row justify-between">
      <div class="pt-6 text-primary-contrast">
        <h1 class="title mt-0 mb-1 text-primary-contrast">{{title}}</h1>
        <vex-breadcrumbs [crumbs]="[title]"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>

  <vex-page-layout-content class="-mt-16 px-gutter">

    <vex-data-table *ngIf="typeView === 'list'"
      [title]="title"
      [selection]="selection"
      [data]="dataSource"
      [columns]="columns"
      [config]="configuration"
      [typeViewShow]="true"
      [typeView]="typeView"

      (create)="onCreate()"
      (clickRow)="onClickRow($event)"
      (refresh)="onRefresh($event)"
      (search)="onSearch($event)"
      (send)="getAction($event)"
      (changeView)="onChangeView($event)"
    ></vex-data-table>

    <div *ngIf="typeView === 'grid'" class="-mt-16">
      <div class="card overflow-auto -mt-16">
        <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
    
          <div class="bg-card rounded-full border px-4" fxFlex="72.5" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
            fxLayoutAlign="start center">
            <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
            <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
              [placeholder]="('COMMON.SEARCH' | translate) + '...'" type="search">
          </div>
    
          <span fxFlex></span>
    
          <button class="ml-4" fxFlex="none" fxHide.gt-xs mat-icon-button type="button">
            <mat-icon [icIcon]="icSearch"></mat-icon>
          </button>
    
          <button class="ml-4" fxFlex="none" mat-icon-button (click)="onChangeView(typeView)" color="primary"
            [matTooltip]="(typeView === 'grid') ? 'Exibir em Lista' : 'Exibir em Grade'" type="button">
            <mat-icon [icIcon]="(typeView === 'grid') ? icGridOn : icGridOff"></mat-icon>
          </button>
    
          <button mat-icon-button (click)="onCreate()" color="primary" [matTooltip]="'BUTTON.ADD' | translate">
            <mat-icon [icIcon]="icAddCircle"></mat-icon>
          </button>
    
          <button mat-icon-button (click)="onRefresh()" color="primary" [matTooltip]="'BUTTON.REFRESH' | translate">
            <mat-icon [icIcon]="icRefresh"></mat-icon>
          </button>
    
        </div>
      </div>

      <div fxLayout="row">

        <div class="overflow-y-auto" fxFlex="auto">
          <div class="p-gutter" vexContainer 
            gdColumns="1fr 1fr 1fr 1fr"
            gdColumns.lt-md="1fr 1fr"
            gdColumns.xs="1fr"
            gdGap="24px">
  
            <div class="card overflow-hidden" *ngFor="let user of dataSource">
              <div class="p-4 text-center hover:bg-hover trans-ease-out cursor-pointer relative"
                   matRipple>
                <img [src]="user?.avatar" class="avatar h-24 w-24 mx-auto" (click)="onClickRow(user)">
            
                <h2 class="title mb-1 mt-3">{{ user?.nome }}</h2>
                <div class="body-2 text-primary">
                  <ic-icon [icon]="icBusiness" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
                  <span>{{ user?.perfil }}</span>
                </div>
            
                <div class="body-2 text-primary">
                  <ic-icon [icon]="icPhone" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
                  <span>{{ user?.telefone }}</span>
                </div>
            
                <button class="absolute top-2 right-2" mat-icon-button type="button" (click)="onStarred(user)">
                  <mat-icon [icIcon]="(user?.isFavorito) ? icStar : icStarBorder" [class]="(user?.isFavorito) ? 'text-amber' : ''"></mat-icon>
                </button>
              </div>
            
              <div class="bg-app-bar p-2" fxLayout="row" fxLayoutAlign="space-around center">
                <button class="text-primary" mat-icon-button type="button">
                  <mat-icon [icIcon]="icPhone"></mat-icon>
                </button>
            
                <button class="text-primary" mat-icon-button type="button">
                  <mat-icon [icIcon]="icMail"></mat-icon>
                </button>
            
                <button class="text-primary" mat-icon-button type="button">
                  <mat-icon [icIcon]="icChat"></mat-icon>
                </button>
              </div>
            </div>
  
          </div>
        </div>

      </div>

    </div>

  </vex-page-layout-content>

</vex-page-layout>
import { ActionTypes } from '../notification/actions';
import { ActionInterface } from 'app/shared/interfaces/action.interface';
import { NotificationCount } from 'app/shared/models/notiifcation-count.model';

export const initialState = new NotificationCount();

export function notificationReducer(state = initialState, action: ActionInterface): NotificationCount {
    switch (action.type) {
        case ActionTypes.SET: {
                state = action.payload;
                return state;
            }

        case ActionTypes.CLEAR: {
                state = initialState;
                return state;
            }

        default:
            return state;
    }
}

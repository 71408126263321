<div fxFlex="100" [formGroup]="formGroup">
  <label>{{ label }} {{ checkRequired() ? ' *' : '' }}</label>
  <mat-radio-group [fxLayout]="layout" fxLayoutGap="20px" [disabled]="disabled"
    [formControlName]="formcontrolname" (change)="setValue($event)">
    <mat-radio-button *ngFor="let radio of radios" [value]="radio.valueField" color="primary" 
      [checked]="radio.valueField == checked ? true : false">
      {{ radio.displayField }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-error
    *ngIf="!formGroup?.get(formcontrolname).value && formGroup?.get(formcontrolname).touched && formGroup.touched">
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>
</div>
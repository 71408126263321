import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { translateConfig } from 'app/shared/config/translate-config';
import { MaterialModule } from 'app/shared/material/material.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { ErrorsComponent } from './errors/errors.component';
import { InputAutocompleteComponent } from './input-autocomplete/input-autocomplete.component';
import { InputColorComponent } from './input-color/input-color.component';
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { InputDatepickerRangeComponent } from './input-datepicker-range/input-datepicker-range.component';
import { InputDatepickerComponent } from './input-datepicker/input-datepicker.component';
import { InputDatetimepickerComponent } from './input-datetimepicker/input-datetimepicker.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { InputFileComponent } from './input-file/input-file.component';
import { InputImageFileComponent } from './input-image-file/input-image-file.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputRadioGroupComponent } from './input-radio-group/input-radio-group.component';
import { InputSelectMultipleComponent } from './input-select-multiple/input-select-multiple.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputToggleComponent } from './input-toggle/input-toggle.component';
import { InputVolumeComponent } from './input-volume/input-volume.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { IconModule } from '@visurel/iconify-angular';

@NgModule({
  declarations: [
    ErrorsComponent,
    InputSelectComponent,
    InputTextComponent,
    InputNumberComponent,
    InputAutocompleteComponent,
    InputSelectMultipleComponent,
    InputToggleComponent,
    InputDatepickerComponent,
    InputDatetimepickerComponent,
    InputDatepickerRangeComponent,
    InputFileComponent,
    InputRadioGroupComponent,
    InputEmailComponent,
    InputPasswordComponent,
    TextAreaComponent,
    InputImageFileComponent,
    InputCurrencyComponent,
    InputVolumeComponent,
    InputColorComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    FormsModule,
    IconModule,
    MaterialFileInputModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    TranslateModule.forChild(translateConfig)
  ],
  exports: [
    ErrorsComponent,
    InputTextComponent,
    InputNumberComponent,
    InputSelectComponent,
    InputAutocompleteComponent,
    InputSelectMultipleComponent,
    InputToggleComponent,
    InputDatepickerComponent,
    InputDatetimepickerComponent,
    InputDatepickerRangeComponent,
    InputFileComponent,
    InputImageFileComponent,
    InputRadioGroupComponent,
    InputEmailComponent,
    InputPasswordComponent,
    TextAreaComponent,
    InputCurrencyComponent,
    InputVolumeComponent,
    InputColorComponent,
  ]
})
export class FormComponentsModule { }

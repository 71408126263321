<mat-form-field [formGroup]="formGroup" class="flex-auto" fxFlex="100" style="width: 150px;">
  <mat-label>{{ label }}</mat-label>
  <div fxLayout="row">
    <input [readonly]="readonly" [required]="checkRequired(formcontrolname)" matInput
      [matDatepicker]="picker" [placeholder]="placeholder" [formControlName]="formcontrolname" autocomplete="off"
      [min]="minDate" [max]="maxDate" (dateChange)="verifyDate($event)" (click)="picker.open()"
      (focus)="picker.open()" >
    <mat-icon matTooltip="Limpar" matTooltipPosition="above"
      *ngIf="(formGroup.get(formcontrolname).value && cleanInput)" (click)="resetField()" class="icon">close</mat-icon>
  </div>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error>
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>
</mat-form-field>

export class PageConfig {

    data(rand: boolean = false){

        let data = [
            {
                checkbox: true,
                status: true,
                id: 1,
                descricao: 'Amil Dental',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 2,
                descricao: 'Bradesco Seguros',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 3,
                descricao: 'Hapvida +Odonto',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 4,
                descricao: 'Interodonto',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 5,
                descricao: 'Metlife',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 6,
                descricao: 'OdontoPrev',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 7,
                descricao: 'SulAmérica Odonto',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 8,
                descricao: 'Unimed Odonto',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
            {
                checkbox: true,
                status: true,
                id: 9,
                descricao: 'Uniodonto',
                btn_del: {icon: 'delete_outline', value: 'delete'},
            },
        ];

        return data;

    }
    
}
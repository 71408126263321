<mat-form-field [formGroup]="formGroup" fxFlex="100" style="width: 100%;">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControlName]="formcontrolname" (selectionChange)="onChangeSelect($event)" 
        [required]="checkRequired()" multiple style="width: 100%;">

        <mat-option *ngIf="searchField">
            <ngx-mat-select-search [placeholderLabel]="'COMMON.SEARCH' | translate" [noEntriesFoundLabel]="noEntriesFoundLabel || 'COMMON.NO_RECORD_FOUND' | translate"
                [formControl]="dataFilterCtrl"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let item of filteredData | async" [value]="item[valueField]">
            {{ item[displayField] }} <span *ngIf="secondDisplayField">({{item[secondDisplayField]}})</span>
        </mat-option>
    </mat-select>

    <mat-icon *ngIf="iconName" matSuffix class="secondary-text">{{iconName}}</mat-icon>

    <mat-error>
        <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
    </mat-error>
</mat-form-field>
export class PageConfig {

    data(){

        let data = [];
        let qtd = 5;

        for (let i = 0; i < qtd; i++) {

            data.push(
                {
                    checkbox: true,
                    status: true,
                    id: i+1,
                    idioma: 'Idioma ' + (i+1),
                    ano: '2021',
                    btn_del: {icon: 'delete_outline', value: 'delete'},
                },
            );
        
        }
        return data;

    }
    
}
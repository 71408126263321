<mat-form-field class="pointer" [formGroup]="formGroup" fxFlex="100">
  <mat-label> {{ label }} </mat-label>

  <ngx-mat-file-input [accept]="accept" [formControlName]="formcontrolname" [placeholder]="placeholder">
  </ngx-mat-file-input>
  <mat-icon fxFlex fxLayoutAlign="end" [icIcon]="icAttachment"></mat-icon>

  <mat-error>
    <app-errors [formcontrolname]="formcontrolname" [formGroup]="formGroup"></app-errors>
  </mat-error>

</mat-form-field>
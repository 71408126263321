import { Component, OnInit, HostListener, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DefaultParams } from 'app/shared/enums/default';
import { UtilsService } from 'app/shared/services/utils.service';

@Component({
  selector: 'app-input-image-file',
  templateUrl: './input-image-file.component.html',
  styleUrls: ['./input-image-file.component.scss']
})
export class InputImageFileComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formcontrolname: string;
  @Input() image: string | ArrayBuffer = null;
  @Input() disabled: boolean;

  @Output() insertedFile = new EventEmitter();

  touched = false;
  defaultImage = DefaultParams.DEFAULT_AVATAR;
  onChange: () => {};

  @ViewChild('inputField', { read: ElementRef, static: true }) input: ElementRef;

  constructor(
    private _utilsService: UtilsService
  ) { }

  ngOnInit(): void { 
    this.formGroup.get(this.formcontrolname).valueChanges.subscribe(res => {
      this.image = res;
    });
  }

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList) {
    const file = event && event.item(0);

    if (file && file.type.match(/image\//)) {
      const reader = new FileReader();
      reader.onload = e => {
        const imageb64 = reader.result;
        this.image = imageb64;
        this.formGroup.get(this.formcontrolname).setValue(imageb64);
      };
      reader.readAsDataURL(file);
    } else {
      this.image = null;
      this.formGroup.get(this.formcontrolname).setValue(null);
    }
  }

  checkRequired(): boolean {
    return this._utilsService.hasRequiredField(this.formGroup.get(this.formcontrolname));
  }

  clickInput() {
    if (this.input.nativeElement && !this.disabled) {
      this.input.nativeElement.click();
    }
  }

  isDisabled(): boolean {
    return this.formGroup.get(this.formcontrolname).disabled;
  }
}

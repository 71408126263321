import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilsService } from 'app/shared/services/utils.service';
import { isString } from 'lodash';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formcontrolname: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() iconName: string;
  @Input() maxLength: number;
  @Input() mask: string;
  @Input() disabled: boolean;

  constructor(
    private _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    const input = this.formGroup.get(this.formcontrolname);
    if (input) { this.onTreatmentMask(input.value); }
  }

  checkRequired(): boolean {
    return this._utilsService.hasRequiredField(this.formGroup.get(this.formcontrolname));
  }

  onChange(value: string): string {
    const lastLetter = value.toString().slice(-2);

    if (value.length >= this.maxLength) {
      return value = value.substr(0, (this.maxLength - 1));
    }

    if (value[0] === (' ' || undefined)) {
      return value = value.trim();
    }

    if (lastLetter[0] === ' ' && lastLetter[1] === ' ') {
      return value = value.substr(0, (value.length - 1));
    }


    if (value.trim() === '') {
      return null;
    } else {
      return value;
    }
  }

  onBlur(): void {
    const input = this.formGroup.get(this.formcontrolname);
    if (input.value) { input.setValue(input.value.trim()); }
  }

  onTreatmentMask(value: string = '') {
    if (isString(value)) {
      const masks = {
        cnpj: '00.000.000/0000-00',
        cpf: '000.000.000-00',
      };

      if (masks.hasOwnProperty(this.formcontrolname)) {
        return this.mask = masks[this.formcontrolname];
      }
    }
  }

}

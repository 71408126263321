import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'app-input-volume',
  templateUrl: './input-volume.component.html',
  styleUrls: ['./input-volume.component.scss']
})
export class InputVolumeComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() formcontrolname: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() iconName: string;
  @Input() disabled: boolean;
  
  @Output() changeValue = new EventEmitter();

  constructor(
      private _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    const input = this.formGroup.get(this.formcontrolname);
  }

  checkRequired(): boolean {
    return this._utilsService.hasRequiredField(this.formGroup.get(this.formcontrolname));
  }

  onChange(value: string): void {
    this.changeValue.emit();
    /*const lastLetter = value.toString().slice(-2);

    if (value[0] === (' ' || undefined)) {
      return value = value.trim();
    }

    if (lastLetter[0] == ' ' && lastLetter[1] == ' ') {
      return value = value.substr(0, (value.length - 1));
    }


    if (value.trim() === '') {
      return null;
    } else {
      return value;
    }*/
  }

  onBlur(): void {
    const input = this.formGroup.get(this.formcontrolname);
    if (input.value) input.setValue(input.value.trim());
  }

}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NotificationCount } from 'app/shared/models/notiifcation-count.model';
import { Auth } from 'app/shared/models/auth.model';
import { SetNotificationData } from 'app/store/notification/actions';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client/dist/socket.io';
// import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  authData: Observable<Auth>;
  msgError;
  socket;
  socketUser;
  user;

  constructor(
    private _store: Store<{ auth: Auth }>,
    private _storeMessage: Store<{ notification: NotificationCount }>,
    // private _notificationService: NotificationService,
    private _router: Router,
  ) {
    this.authData = this._store.pipe(select('auth'));
    this.authData.subscribe(data => {
      if (data.hasOwnProperty('user')) {
        this.user = data.user;
        // this.setupSocketConnection(data.user.id);
      }
    });
  }

  setupSocketConnection(idUser): void {
    this.socket = io.connect(`${environment.API}/notify`, { query: { idUser } });
    this.listeningNotificationCount();
    this.socketUser = io.connect(`${environment.API}/users`, { query: { idUser } });
    this.listeningBlockedUser();
  }

  listeningNotificationCount(): void {
    this.socket.on('notificationCount', (data: any) => {
      if (data.hasErr) {
        this.msgError = data[0];
      } else {
        // this._notificationService.getUnread().subscribe((result: any) => {
        //   let count = result;
        //   if (count > 99) {
        //     count = '99+';
        //   } else {
        //     count = count.toString() || '0';
        //   }
        //   this._storeMessage.dispatch(SetNotificationData({ count }));
        // });
      }
    });
  }

  listeningBlockedUser(): void {
    this.socketUser.on('user-blocked', (data: any) => {
      if (data.hasErr) {
        this.msgError = data[0];
      } else {
        if (data.idUser === this.user.idUser) {
          this._router.navigate(['/not-auth/logout']);
        }
      }
    });
  }
}

import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
  public constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  ofLabel = '/';

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };

  public getAndInitTranslations(): void {

    this.translate.get('PAGINATOR.ITEMS_PER_PAGE').subscribe(res => {
      this.itemsPerPageLabel = res;
      this.changes.next();
    });

    this.translate.get('PAGINATOR.NEXT_PAGE').subscribe(res => {
      this.nextPageLabel = res;
      this.changes.next();
    });

    this.translate.get('PAGINATOR.PREV_PAGE').subscribe(res => {
      this.previousPageLabel = res;
      this.changes.next();
    });
    this.translate.get('PAGINATOR.FIRST_PAGE').subscribe(res => {
      this.firstPageLabel = res;
      this.changes.next();
    });
    this.translate.get('PAGINATOR.LAST_PAGE').subscribe(res => {
      this.lastPageLabel = res;
      this.changes.next();
    });

    this.translate.get('PAGINATOR.OF').subscribe(res => {
      this.ofLabel = res;
      this.changes.next();
    });
  }
}
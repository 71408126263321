import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';

const routes: VexRoutes = [
    {
        path: 'login',
        component: LoginComponent
    },
    /* {
        path: 'register',
        component: RegisterComponent
    }, */
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    // {
    //     path: 'reset-password',
    //     loadChildren: () => import('./reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
    // },
    // {
    //     path: 'reset-password/:token',
    //     loadChildren: () => import('./reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
    // },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: '**',
        redirectTo: 'login'
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NotAuthRoutingModule { }

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Operation } from 'app/shared/enums/operation';

import { GlobalService } from 'app/shared/services/global.service';

import icClose from '@iconify/icons-ic/twotone-close';
import icSave from '@iconify/icons-ic/twotone-save';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';

import icDone from '@iconify/icons-ic/twotone-done';
import { ToastService } from 'app/shared/services/toast.service';
import { SwalService } from 'app/shared/services/swal.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Config } from 'app/shared/components/several-components/data-table/config';
import { TableColumn } from '@vex/interfaces/table-column.interface';

import * as PageConfigAreas from 'app/mockups/page-config-areas';
import * as PageConfigEspecialidades from 'app/mockups/page-config-especialidades';

import { MatDialog } from '@angular/material/dialog';
import { ModalEspecialidadesComponent } from '../modal-especialidades/modal';

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  constructor(
    public translate: TranslateService,
    private _route: ActivatedRoute,
    public globalService: GlobalService,
    private _toastService: ToastService,
    private _swalService: SwalService,
    private dialog: MatDialog
  ) { }

  title = 'Áreas';
  action;
  operation: Operation;

  formStepper1: FormGroup;

  id: number;
  item: any;
  subscription: Subscription;
  dataItem: any;

  icClose = icClose;
  icSave = icSave;
  icEdit = icEdit;
  icDelete = icDelete;

  icDone = icDone;

  //TABLE
  paginationInitial = { page: 1, limit: 10 };
  options = {};

  selection = new SelectionModel<any>(true, []);
  configuration = new Config({
    toggleButtons: {
      status: (item) => this.onToggleStatus(item),
    },
  }, 0);

  //TABLE ESPECIALIDADES
  dataSourceEspecialidades: any = [];
  dataSourceBaseEspecialidades: any = [];

  columnsEspecialidades: TableColumn<any>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Status', property: 'status', type: 'toggle', visible: true },
    { label: 'Id', property: 'id', type: 'id', visible: true },
    /* { label: 'Status', property: 'status', type: 'toggle', visible: true }, */
    
    { label: 'Descrição', property: 'descricao', type: 'text', visible: true },
  ];

  async ngOnInit(): Promise<void> {

    this.createForm();
    this.onRefresh();

    await this._route.params.subscribe(async (params) => {

      if(params.id === 'new'){
        this.action = 'Inserindo';
        this.operation = Operation.NEW;
      
      }else{
        this.id = parseInt(params.id);

        this.dataItem = await new PageConfigAreas.PageConfig().data().filter(item => item.id === this.id)[0];
        this.fillForm();

        if(this.id === 1){ this.dataSourceEspecialidades = await new PageConfigEspecialidades.PageConfig().data(); }
    
        this.dataSourceBaseEspecialidades = this.dataSourceEspecialidades;
        this.configuration.total = this.dataSourceEspecialidades.length;
        
        this.action = 'Visualizando';
        this.operation = Operation.VIEW;

        this.formStepper1.disable();
      }

    });
  }

  onRefresh(event?): void { }

  createForm(): void {
    this.formStepper1 = new FormGroup({
      id: new FormControl(null, Validators.required),
      descricao: new FormControl(null, Validators.required),
      status: new FormControl(true),
    });

    this.formStepper1.get('id').disable();
  }

  fillForm(): void {
    this.formStepper1.patchValue(this.dataItem);
  }

  onEdit(){
    this.action = 'Editando';
    this.operation = Operation.EDIT;

    this.formStepper1.enable();
    this.formStepper1.get('id').disable();
  }


  onSave(): void {
    this._toastService.success('O registro foi salvo na base de dados!');
    this.onClose();
  }

  onDelete(item?): void {
    this._swalService.confirm(`Excluir registro #${(item) ? item.id : this.id}`, 'Tem certeza que deseja excluir este registro?')
    .then((res) => {
      if(res.value){
        this._toastService.success('O registro foi excluído!');
        this.onClose();
      }
    })    
  }

  onClose(): void {
    this.formStepper1.reset();
    this.globalService.backBtn();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onToggleStatus(row): void { }

  onSearch(search: string, type) {

    switch (type) {
      case 'Especialidades': 
        this.dataSourceEspecialidades = this.globalService.searchLocal(search, this.dataSourceBaseEspecialidades);
        break;

      default:
        true;
    }
  }

  getAction(event):void {
    switch (event.action) {
      case 'delete': 
        this.onDelete(event.row);
        break;

      default:
        true;
    }
  }

  onCreateEspecialidade(): void {

    this.dialog.open(ModalEspecialidadesComponent, { panelClass: 'dialog-default', data: [this.dataItem.id] })
    .afterClosed().subscribe((data) => {
      if (data) {
        this.onRefresh();
      }
    });

  }

}

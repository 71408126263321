import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { translateConfig } from 'app/shared/config/translate-config';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule.forChild(translateConfig)
  ],
  declarations: [
    LoadingComponent
  ],
  exports: [
    LoadingComponent
  ]
})

export class LoadingModule { }
